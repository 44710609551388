export class Documento {
    id: string;
    nombre: string;
    descripcion: string;
    fecha_emision: string;
    fecha_termino: string;
    fecha_subida: any;
    id_usuario: string;
    id_proyecto: string;
    id_empresa: string;
    tipo_documentos: string | number;
    ruta: string;
    activo: string;
}