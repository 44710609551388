import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { AppService } from '../services/app.service';
import { ToastrService } from 'ngx-toastr';
import { pdfDefaultOptions } from 'ngx-extended-pdf-viewer';
import { EmpresaService } from '../services/empresa.service';
import { Empresa } from '../models/empresa';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-rendicion',
  templateUrl: './rendicion.component.html',
  styleUrls: ['./rendicion.component.scss']
})
export class RendicionComponent implements OnInit {

  get auth() {
    return this.authService.auth;
  } 
  public empresas : Empresa[];
  public idEmpresasString: string;
  public idEmpresas = new Array();
  public rendiciones;
  public onlydocument;
  public typeimg;
  public idProyectos;
  public proyectosArray = {
    id_empresa: [],
    name: '',
    tipo_documentos: '',
    tipo: '',
    length
  }

  constructor(
    private authService: AuthService, 
    private appService: AppService, 
    private toastr: ToastrService,
    private empresaService:EmpresaService, 
    private modalService: NgbModal,
    ) {
    pdfDefaultOptions.assetsFolder = 'bleeding-edge';
  }

  ngOnInit(): void {
    this.loadEmpresas();
    this.getRendiciones();
    this.getProyectos();
  }

  loadEmpresas() {
    this.empresaService.getEmpresas().subscribe(empresas => this.empresas = empresas);
  }

  getRendiciones() {
    setTimeout(() => {
      for(let i = 0; i < this.empresas.length; i++) {
        this.idEmpresas[i] = this.empresas[i].id;
      }

      this.idEmpresasString = this.idEmpresas.toString();

      if(this.auth['data'].role == 1) {
        this.appService.getAllRend().subscribe(
          datos => {
            if(datos['code'] == '200') {
              this.rendiciones = datos['data'];
              console.log(this.rendiciones);
            }
          }
        )
      } else {
        this.appService.getRend(this.idProyectos).subscribe(
          datos => {
            if(datos['code'] == '200') {
              this.rendiciones = datos['data'];
              console.log(this.rendiciones);
            }
          }
        )
      }
    }, 600);
  }

  // Obtenemos los proyectos asignados
  getProyectos() {
    this.authService.getUserProyect(this.auth['data'].id).subscribe(
      response => {
        if(response['code'] == 200) {
          this.proyectosArray = response['data'];
          this.idProyectos = [];
          for(let i = 0; i < this.proyectosArray.length; i++) {
            this.idProyectos.push(this.proyectosArray[i].id_proyecto);
          }       
        }
      },
      error => {
        console.log(<any>error);
      }
    );
  }

  open(content, rendicion) {
    this.onlydocument = rendicion;
    let route;
    route = this.onlydocument.ruta.split('/');
    route = route[route.length - 1];
    route = this.onlydocument.ruta.split('.');
    route = route[route.length - 1];
    this.typeimg = route;
    this.modalService.open(content, { size: 'xl' });
  }

  donwload(onlydocument) {
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', 'assets/'+onlydocument.ruta+'');
    link.setAttribute('download', onlydocument.ruta);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  deleteDocument(onlydocument) {
    this.appService.deleteDocumento(onlydocument.id).subscribe(
      response => {
        if(response['code'] == 200) {
          this.getRendiciones();
          this.modalService.dismissAll();
          this.toastr.success('Éxito al eliminar', 'Rendición eliminada');
        } else {
          this.toastr.error('Error al eliminar', 'Rendición no eliminada');
        }
      },
      error => {
        console.log(<any>error);
        this.toastr.error('Error al eliminar', 'Rendición no eliminada');
      }
    );
  }

}
