import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { EmpresaService } from '../services/empresa.service';
import { Empresa } from '../models/empresa';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  get auth() {
    return this.authService.auth;
  }

  get authrole() {
    return this.authService.authrole;
  }

  public empresas : Empresa[];
  public data: any;
  public active = new Array();

  constructor(private router: Router, private authService: AuthService, private empresaService: EmpresaService) {
  }

  ngOnInit(): void {
    this.loadEmpresas();
  }

  loadEmpresas() {
    this.empresaService.getEmpresas().subscribe(empresas => this.empresas = empresas);

    /*setTimeout(() => {
      for(let i = 0; i < this.empresas.length; i++) {
        if(this.empresas[i].activo == '0') {
          this.active[i] = 0;
          this.empresas[i].activo = 'Activa';
        } else {
          this.active[i] = 1;
          this.empresas[i].activo = 'Desactivada';
        }
      }
    }, 800);*/
  }
}
