import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { EmpresaService } from '../services/empresa.service';
import { Empresa } from '../models/empresa';
import { AppService } from '../services/app.service';
import { ToastrService } from 'ngx-toastr';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { Documento } from '../models/documento';
import { pdfDefaultOptions } from 'ngx-extended-pdf-viewer';
import { UploadService } from '../services/upload.service';
import { BooleanLiteral } from 'typescript';

@Component({
  selector: 'app-proyectos',
  templateUrl: './proyectos.component.html',
  styleUrls: ['./proyectos.component.scss']
})
export class ProyectosComponent implements OnInit {
  
  get auth() {
    return this.authService.auth;
  }

  // Subida de documentacion
  public documento : Documento;
  public nombre: string | null;
  public pdfSource: string;
  public filterDocuments;
  public sanitizedUrl;
  public typeimg;
  public empresasSelected: any;
  public empresasSelectednew: any;
  public empresas : Empresa[];
  public allempresas : Empresa[];
  public empresaMo: Empresa;
  public addEmpresa: Empresa;
  public editar: boolean = false;
  public empleadoSelect: any;
  public empresasid: any;
  public empleados;
  public empleadosall;
  public data: any;
  public selected;
  public selectedDocumentos;
  public selectedEmpresas;
  public tipo;
  public aleatorio: number;
  public onlydocument;
  public proyectoModal;
  public selectedEmpresa;
  public empresasArrayid = new Array();
  public empresasArraystring: string;
  public adddocumentfile;
  public documentsFilter;
  public subidaok = new Array();
  public allproyectos;
  public camposvacios: boolean = false;
  public empresasProyectos;
  public usertoproyect;
  public documentsFiltertwo;
  public newProyecto = {
    id_empresa: null,
    name: null,
    tipo_documentos: null,
    tipo: null,
  }
  public proyectosArray = {
    id_empresa: [],
    name: '',
    tipo_documentos: '',
    tipo: '',
    length
  }
  public documentofilter = {
    id_empresa: null,
    id_proyecto: null,
  }
  public onlydocumentfilter = {
    id_empresa: null,
    id_proyecto: null,
    tipo_documento: null,
  }
  public archivoHeader = {
    nombre: null,
    nombreArchivo: null,
    base64textString: null,
    nombreUsuario: null,
    id_empresa: null,
  }
  public asignusertoproyect = {
    id_proyecto: 'selected',
    id_usuario: 'selected',
    rank: 'selected',
    permisos: null
  }

  public usuarioproyect = {
    id_usuario: null,
    permisos: null
  }

  public arrayproyecto;

  public typeDocument = [
    'CARPETA TRIBUTARIA PARA SOLICITAR CRÉDITOS', 
    'POSTULACIÓN', 
    'CONTRATO CON BENEFICIARIO', 
    'ANEXO DE CONTRATO CON BENEFICIARIO', 
    'FORMULARIO F 30',
    'CERTIFICADO DE DEUDA',
    'CÉDULA DE IDENTIDAD REPRESENTANTE LEGAL',
    'ESTATUTOS O ESCRITURA DE LA EMPRESA ACTUALIZADOS',
    'CERTIFICADO DE VIGENCIA DE LA EMPRESA',
    'DECLARACIÓN JURADA SIMPLE DE PROBIDAD',
    'DECLARACIÓN JURADA SIMPLE DE CONSANGUINIEDAD',
    'DECLARACIÓN JURADA SIMPLE DE COMPROMISO Y ENTREGA DE INFORMACIÓN',
    'ANEXO 1',
    'ANEXO 2',
    'ANEXO 3',
    'RENDICIÓN'];

  constructor(
    private router: Router, 
    private authService: AuthService, 
    private empresaService: EmpresaService, 
    private appService: AppService, 
    private toastr: ToastrService,
    private modalService: NgbModal,
    private uploadService: UploadService
    ) {
      this.newProyecto.tipo = '3';
      this.newProyecto.tipo_documentos = ['selected'];
      this.newProyecto.id_empresa = ['selected'];
      this.documento = new Documento;
      pdfDefaultOptions.assetsFolder = 'bleeding-edge';
      this.onlydocument = new Documento;
      this.archivoHeader.nombreArchivo = 'Seleccione un documento';
      this.arrayproyecto = new Array();
  }
  ngOnInit(): void {
    this.loadEmpresas();
    this.obtenerEmpleados();
    this.getProyectos();
    this.getAllEmpleados();
    this.getallEmpresa();
  }

  loadEmpresas() {
    this.empresaService.getEmpresas().subscribe(empresas => this.empresas = empresas);
  }

  getEmpresa() {
    this.authService.getEmpresa(this.auth['data'].id).subscribe(
      response => {
        if(response['code'] == 200) {
          this.empresaService.setEmpresas(response['data']);
        }
      },
      error => {
        console.log(<any>error);
      }
    );
  }

  // AGREGAMOS NUEVO PROYECTO (Usamos shift para eliminar el primer elemento del array 'selected')
  addProyect() {
    this.newProyecto.id_empresa.shift();
    this.newProyecto.tipo_documentos.shift();
    this.appService.addProyecto(this.newProyecto).subscribe(
      response => {
        if(response['code'] == 200) {
          this.newProyecto.id_empresa = ['selected'];
          this.newProyecto.name = '';
          this.newProyecto.tipo_documentos = ['selected'];
          this.newProyecto.tipo = '3';
          this.getProyectos();
          this.toastr.success('Éxito al crear', 'Proyecto creado');
        } else {
          this.toastr.error('Error al crear', 'Proyecto no creado');
        }
      },
      error => {
        console.log(<any>error);
        this.toastr.error('Error al crear', 'Proyecto no creado');
      }
    );
  }


  // Obtenemos todas las empresas asignadas a un proyecto
  getEmpresaProyecto(idempresa) {
    this.appService.getEmpresaProyecto(idempresa).subscribe(
      response => {
        if(response['code'] == 200) {
          this.empresasProyectos = response['data'];
          this.selected = [];
          for(let i = 0; i < this.empresasProyectos.length; i++) {
            this.selected.push(this.empresasProyectos[i].id);
          }
          this.adddocumentfile = this.empresasProyectos[0].id;
        }
      },
      error => {
        console.log(<any>error);
      }
    );
  }

  // Obtenemos todas las empresas creadas
  getallEmpresa() {
    this.authService.getAllEmpresas().subscribe(
      response => {
        if(response['code'] == 200) {
          this.allempresas = response['data'];
        }
      },
      error => {
        console.log(<any>error);
      }
    );
  }

  // Obtenemos los proyectos asignados
  getProyectos() {

    if(this.auth['data'].role == 1) {
      this.getAllProyects();
    } else {
      this.authService.getUserProyect(this.auth['data'].id).subscribe(
        response => {
          if(response['code'] == 200) {
            this.proyectosArray = response['data'];
  
            for(let i = 0; i <= response['data'].length; i++) {
              if(this.proyectosArray[i].id_empresa[0] != '') {
                this.proyectosArray[i].id_empresa = this.proyectosArray[i].id_empresa.split(',');
              }
              if(this.proyectosArray[i].tipo_documentos[0] != '') {
                this.proyectosArray[i].tipo_documentos = this.proyectosArray[i].tipo_documentos.split(',');
              }
            }
          }
        },
        error => {
          console.log(<any>error);
        }
      );
    }
  }

  // Obtenemos todos los proyectos (ADMINISTRADOR)
  getAllProyects() {
    this.authService.getAllProyectos().subscribe(
      response => {
        if(response['code'] == 200) {
          if(response['code'] == 200) {
            this.proyectosArray = response['data'];
  
            for(let i = 0; i <= response['data'].length; i++) {
              if(this.proyectosArray[i].id_empresa[0] != '') {
                this.proyectosArray[i].id_empresa = this.proyectosArray[i].id_empresa.split(',');
              }
              if(this.proyectosArray[i].tipo_documentos[0] != '') {
                this.proyectosArray[i].tipo_documentos = this.proyectosArray[i].tipo_documentos.split(',');
              }
            }
          }
        }
      },
      error => {
        console.log(<any>error);
      }
    );
  }

  getDocumentoFilter(proyectomodal) {
    this.arrayproyecto = [];
    this.documentofilter.id_empresa = this.adddocumentfile;
    this.proyectoModal.tipo_documentos = this.proyectoModal.tipo_documentos.sort(function(a, b){ return a - b; });
    this.authService.getDocumentosFilter(this.documentofilter).subscribe(
      response => {
        if(response['code'] == 200) {
          this.documentsFilter = response['data'];

            for(let i = 0; i < this.documentsFilter.length; i++) {
              this.arrayproyecto.push(this.documentsFilter[i].tipo_documento);
            }

            this.arrayproyecto = this.arrayproyecto.sort(function(a, b){ return a - b; });
            
            console.log('###############33');
            console.log(this.arrayproyecto);
            console.log('###############33');


            for(let i = 0; i < this.proyectoModal.tipo_documentos.length; i++) {
              if(this.documentsFilter.length > 1) {
                this.subidaok.push(proyectomodal.tipo_documentos.find(element => element == this.arrayproyecto[i]));
              } else if (this.documentsFilter.length == 1) {
                this.subidaok[i] = this.arrayproyecto[0];
              } else {
                this.subidaok = [];
              }
              console.log('COMPARACION: '+ this.proyectoModal.tipo_documentos[i] + ' - '+ this.subidaok[i]);
              if(this.proyectoModal.tipo_documentos[i] != this.subidaok[i]) {
                this.subidaok.splice(i, 0, null);
              }
            }

          console.log('------------------------');
          console.log(this.proyectoModal.tipo_documentos);
          console.log(this.subidaok);
          console.log('------------------------');


          this.proyectoModal.tipo_documentos = this.proyectoModal.tipo_documentos.sort(function(a, b){ return a - b; });
          //this.subidaok = this.subidaok.sort(function(a, b){ return a - b; });
        }
      },
      error => {
        console.log(<any>error);
      }
    );
  }

  changeEmpresa(id) {
    console.log('Probando');
    this.documentofilter.id_empresa = id;
    this.proyectoModal.tipo_documentos = this.proyectoModal.tipo_documentos.sort(function(a, b){ return a - b; });
    this.authService.getDocumentosFilter(this.documentofilter).subscribe(
      response => {
        if(response['code'] == 200) {
          this.documentsFilter = response['data'];

          setTimeout(() => {
            for(let i = 0; i < this.proyectoModal.tipo_documentos.length; i++) {
              if(this.documentsFilter.length > 1) {
                this.subidaok.push(this.proyectoModal.tipo_documentos.find(element => element == this.documentsFilter[i].tipo_documento));
              } else if (this.documentsFilter.length == 1) {
                this.subidaok[i] = this.documentsFilter[0].tipo_documento;
              } else {
                this.subidaok = [];
              }
              console.log('COMPARACION: '+this.proyectoModal.tipo_documentos[i]+ ' - '+this.subidaok[i]);

              if(this.proyectoModal.tipo_documentos[i] != this.subidaok[i]) {
                this.subidaok.splice(i, 0, null);
              }
            }
          }, 600);

          this.proyectoModal.tipo_documentos = this.proyectoModal.tipo_documentos.sort(function(a, b){ return a - b; });
          //this.subidaok = this.subidaok.sort(function(a, b){ return a - b; });
        }
      },
      error => {
        console.log(<any>error);
      }
    );
  }

  obtenerEmpleados() {
    this.appService.getEmpleados(this.auth['data'].id).subscribe(
      response => {
        if(response['code'] == 200) {
          this.empleados = response['data'];
        } else {
          console.log('Error en la petición');
        }
      },
      error => {
        console.log(<any>error);
      }
    );
  }

  getAllEmpleados() {
    this.appService.getAllEmpleados().subscribe(
      response => {
        if(response['code'] == 200) {
          this.empleadosall = response['data'];
        } else {
          console.log('Error en la petición');
        }
      },
      error => {
        console.log(<any>error);
      }
    );
  }

  // Modal Bootstrap
  openModal(proyecto, content) {
    this.proyectoModal = proyecto;
    this.subidaok = [];
    this.tipo = '';
    this.editar = false;
    this.getUsertoProyect(this.proyectoModal.id);
    this.getEmpresaProyecto(this.proyectoModal.id_empresa);

    if(this.proyectoModal.tipo_documentos.length >= 1 && this.proyectoModal.tipo_documentos[0].toString() != '') {
      this.selectedDocumentos = [];
      let contador = this.proyectoModal.tipo_documentos.length;
      for(let i = 0; i < contador; i++) {
        this.proyectoModal.tipo_documentos[i] = parseInt(this.proyectoModal.tipo_documentos[i]);
        this.selectedDocumentos.push(this.proyectoModal.tipo_documentos[i]);
      }
    } else {
      this.selectedDocumentos = [];
    }

    if(this.proyectoModal.tipo == '0' || this.proyectoModal.tipo == '1') {
      this.tipo = this.proyectoModal.tipo;
    } else {
      this.tipo = '3';
    }

    /*if(this.proyectoModal.id_empresa[0] != '') {
      this.selected = [];
      this.selectedEmpresas = [];
      for(let i = 0; i < this.proyectoModal.id_empresa.length; i++) {
        if(this.proyectoModal.id_empresa[i] == this.empresasArrayid[i]) {
          this.selected.push(this.proyectoModal.id_empresa[i]);
          this.selectedEmpresas[i] = {id_empresa: this.proyectoModal.id_empresa[i], nombre: this.proyectoModal.nombre};
        }
      }
    } else {
      this.selected = [];
      this.selectedEmpresas = [];
    }*/
    this.documentofilter.id_proyecto = this.proyectoModal.id;
    this.getallEmpresa();

    setTimeout(() => {
      this.getDocumentoFilter(this.proyectoModal);
    }, 600);
    this.open(content, this.proyectoModal);
  }

  open(content, proyectoModal) {
    this.modalService.open(content, { size: 'xl' });
  }

  openDocument(tipodocumento, documentsFilter, contentdocument) {
    this.onlydocumentfilter.id_empresa = this.documentofilter.id_empresa;
    this.onlydocumentfilter.id_proyecto = this.documentofilter.id_proyecto;
    this.onlydocumentfilter.tipo_documento = tipodocumento;
    this.authService.getOnlydocument(this.onlydocumentfilter).subscribe(
      response => {
        if(response['code'] == 200) {
          this.onlydocument = response['data'];
          let route;
          route = this.onlydocument[0].ruta.split('/');
          route = route[route.length - 1];
          route = this.onlydocument[0].ruta.split('.');
          route = route[route.length - 1];
          this.typeimg = route;
        } else {
          console.log('Error en la petición');
        }
      },
      error => {
        console.log(<any>error);
      }
    );
    this.openDocumentmodal(contentdocument);
  }

  openDocumentmodal(contentdocument) {
    this.modalService.open(contentdocument, { size: 'xl' });
  }

  deleteDocument(onlydocument, proyectoModal) {
    this.appService.deleteDocumento(onlydocument.id).subscribe(
      response => {
        if(response['code'] == 200) {
          this.getProyectos();
          this.getDocumentoFilter(proyectoModal);
          this.modalService.dismissAll();
          this.documento = new Documento();
          this.archivoHeader.nombreArchivo = 'Seleccione un documento';
          this.toastr.success('Éxito al eliminar', 'Documento eliminado');
        } else {
          this.toastr.error('Error al eliminar', 'Documento no eliminado');
        }
      },
      error => {
        console.log(<any>error);
        this.toastr.error('Error al eliminar', 'Documento no eliminado');
      }
    );
  }

  editarProyecto() {
    if(this.editar == false) {
      this.editar = true;
    } else {
      this.editar = false;
    }
  }

  updateProyecto() {
    this.proyectoModal.tipo = this.tipo;
    this.proyectoModal.id_empresa = this.selected;
    this.proyectoModal.tipo_documentos = this.selectedDocumentos;
    this.appService.updateProyecto(this.proyectoModal.id, this.proyectoModal).subscribe(
      response => {
        if(response['code'] == 200) {
          this.editar = false;
          this.getProyectos();
          this.toastr.success('Éxito al actualizar', 'Proyecto actualizado');
        } else {
          this.toastr.error('Error al actualizar', 'Proyecto no actualizado');
        }
      },
      error => {
        console.log(<any>error);
        this.toastr.error('Error al actualizar', 'Proyecto no actualizado');
      }
    );
  }

  removeProyecto() {
    this.appService.deleteProyecto(this.proyectoModal.id).subscribe(
      response => {
        if(response['code'] == 200) {
          this.editar = false;
          this.getProyectos();
          this.modalService.dismissAll();
          this.toastr.success('Éxito al eliminar', 'Proyecto eliminado');
        } else {
          this.toastr.error('Error al eliminar', 'Proyecto no eliminado');
        }
      },
      error => {
        console.log(<any>error);
        this.toastr.error('Error al eliminar', 'Proyecto no eliminado');
      }
    );
  }

  addUsertoProyect() {
    if(this.asignusertoproyect.id_usuario != 'selected' && this.asignusertoproyect.id_proyecto != 'selected' && this.asignusertoproyect.rank != 'selected') {
      if(this.asignusertoproyect.rank == '0') {
        this.asignusertoproyect.permisos = '1';
      } else {
        this.asignusertoproyect.permisos = '2';
      }
      this.appService.addUserProyect(this.asignusertoproyect).subscribe(
        response => {
          if(response['code'] == 200) {
            this.getProyectos();
            this.camposvacios = false;
            this.asignusertoproyect.id_usuario = 'selected';
            this.asignusertoproyect.id_proyecto = 'selected';
            this.asignusertoproyect.rank = 'selected';
  
            this.toastr.success('Éxito al asignar', 'Proyecto asignado');
          } else {
            this.toastr.error('Error al asignar', 'Proyecto no asignado');
          }
        },
        error => {
          console.log(<any>error);
          this.toastr.error('Error al asignar', 'Proyecto no asignado');
        }
      );
    } else {
      this.camposvacios = true;
    }
  }

  // Obtener empleados asignados a un proyecto
  getUsertoProyect(idproyecto) {
    this.appService.getUsertoproyect(idproyecto).subscribe(
      response => {
        if(response['code'] == 200) {
          console.log(response['data']);
          this.usertoproyect = response['data'];
          this.usertoproyect.sort(function (a) {
            if (a.rank == 1) {
              return 1;
            }
            return 0;
          });
        } else {
          this.toastr.error('Error al asignar', 'Proyecto no asignado');
        }
      },
      error => {
        console.log(<any>error);
        this.toastr.error('Error al asignar', 'Proyecto no asignado');
      }
    );
  }

  deleteUsertoProyect(id, usuario) {
    this.asignusertoproyect.permisos = '0';
    this.usuarioproyect.permisos = this.asignusertoproyect.permisos;
    this.usuarioproyect.id_usuario = usuario;
    this.appService.deleteUserProyect(id, this.usuarioproyect).subscribe(
      response => {
        if(response['code'] == 200) {
          this.getUsertoProyect(this.proyectoModal.id);
          this.toastr.success('Éxito al eliminar', 'Usuario eliminado');
        } else {
          this.toastr.error('Error al eliminar', 'Usuario no eliminado');
        }
      },
      error => {
        console.log(<any>error);
        this.toastr.error('Error al eliminar', 'Usuario no eliminado');
      }
    );
  }

  // Subir documento
  seleccionarArchivoHeader(event) {
    var filesHeader = event.target.files;
    var fileHeader = filesHeader[0];
    this.archivoHeader.nombreArchivo = fileHeader.name;

    if (filesHeader && fileHeader) {
      var readerHeader = new FileReader();
      readerHeader.onload = this._handleReaderLoadedHeader.bind(this);
      readerHeader.readAsBinaryString(fileHeader);
    }
  }

  _handleReaderLoadedHeader(readerEvt) {
    var binaryStringHeader = readerEvt.target.result;
    this.archivoHeader.base64textString = btoa(binaryStringHeader);
  }

  donwload(onlydocument) {
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', 'assets/'+onlydocument.ruta+'');
    link.setAttribute('download', onlydocument.ruta);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  uploadHeader(typedocument, content) {
    this.aleatorio = Math.round(Math.random()*2000);
    this.archivoHeader.nombre = this.aleatorio + this.archivoHeader.nombreArchivo;
    this.archivoHeader.nombreArchivo = this.archivoHeader.nombre;
    this.archivoHeader.nombreUsuario = this.auth['data'].username;
    this.documento.id_empresa = this.adddocumentfile;
    this.uploadService.uploadFile(this.archivoHeader).subscribe(
      datos => {
        if(datos['resultado'] == 'OK') {
          this.documento.id_usuario = this.auth['data'].id;
          this.documento.ruta = this.archivoHeader.nombreArchivo;
          this.documento.id_proyecto = this.proyectoModal.id;
          this.documento.tipo_documentos = typedocument;

          if(this.documento.descripcion == null ) {
            this.documento.descripcion = 'Sin descripción';
          }

          this.appService.addDocument(this.documento).subscribe(
            datos => {
              if(datos['code'] == 200) {
                this.toastr.success('Éxito al subir', 'Documento subido');
                this.modalService.dismissAll();
                this.openModal(this.proyectoModal, content);
                this.documento = new Documento;
                this.archivoHeader.nombreArchivo = 'Seleccione un documento';
              } else {
                console.log('Error al insertar');
                this.toastr.error('Error al subir', 'Documento no subido');
              }
            }
          )
        }
      }
    )
  }
}
