import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HeaderComponent } from '../app/shared/header/header.component';
import { ProfileComponent } from './profile/profile.component';
import { HomeComponent } from './home/home.component';
import { DocumentosComponent } from './documentos/documentos.component';
import { RendicionComponent } from './rendicion/rendicion.component';
import { LoginComponent } from './login/login.component';
import { AuthGuard } from './guards/auth.guard';
import { EmpresaComponent } from './empresa/empresa.component';
import { ProyectosComponent } from './proyectos/proyectos.component';
import { UsuariosComponent } from './usuarios/usuarios.component';


const routes: Routes = [
  {
    path: '',
    component: LoginComponent,
    pathMatch: 'full'
  },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [ AuthGuard ]
  },
  {
    path: 'documentos',
    component: DocumentosComponent,
    canActivate: [ AuthGuard ]
  },
  {
    path: 'rendicion',
    component: RendicionComponent,
    canActivate: [ AuthGuard ]
  },
  {
    path: 'empresa',
    component: EmpresaComponent,
    canActivate: [ AuthGuard ]
  },
  {
    path: 'perfil',
    component: ProfileComponent,
    canActivate: [ AuthGuard ]
  },
  {
    path: 'proyectos',
    component: ProyectosComponent,
    canActivate: [ AuthGuard ]
  },
  {
    path: 'usuarios',
    component: UsuariosComponent,
    canActivate: [ AuthGuard ]
  },
  {
    path: '**',
    redirectTo: ''
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
