<div class="login-box">
    <div class="logo-login">
        <img class="mb-4" src="https://agasdata.cl/wp-content/uploads/2021/08/Logo-agasdata-1.png" alt="logo-login">
    </div>

    <div class="login">
        <div *ngIf="error_login" class="alert alert-danger" role="alert">
            <i class="bi bi-exclamation-lg"></i> Nombre de usuario o password inválido
        </div>
        <div class="form-group">
            <input type="text" class="form-control" placeholder="usuario" [(ngModel)]="usuario.username"/>
        </div>
        <div class="form-group">
            <input type="password" class="form-control" placeholder="password" [(ngModel)]="usuario.password"/>
        </div>
        <button class="btn btn-block btn-login" (click)="login()">Iniciar sesión</button>
    </div>
</div>