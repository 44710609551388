import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HeaderComponent } from './shared/header/header.component';
import { FooterComponent } from './shared/footer/footer.component';
import { LoginComponent } from './login/login.component';
import { ProfileComponent } from './profile/profile.component';

import { AppRoutingModule } from './app-routing.module';
import { DocumentosComponent } from './documentos/documentos.component';
import { RendicionComponent } from './rendicion/rendicion.component';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { AdministradorComponent } from './administrador/administrador.component';
import { AuthService } from './services/auth.service';
import { EmpresaService } from './services/empresa.service';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './home/home.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule, ToastNoAnimation, ToastNoAnimationModule } from 'ngx-toastr';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { FilterPipe } from './pipes/filter.pipe';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { EmpresaComponent } from './empresa/empresa.component';
import { ProyectosComponent } from './proyectos/proyectos.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { UsuariosComponent } from './usuarios/usuarios.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    LoginComponent,
    ProfileComponent,
    DocumentosComponent,
    RendicionComponent,
    AdministradorComponent,
    HomeComponent,
    FilterPipe,
    EmpresaComponent,
    ProyectosComponent,
    UsuariosComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    FormsModule,
    NgbModule,
    NgSelectModule,
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot(), // ToastrModule added
    ToastNoAnimationModule.forRoot(),
    PdfViewerModule,
    NgxExtendedPdfViewerModule
  ],
  providers: [AuthService, EmpresaService],
  bootstrap: [AppComponent]
})
export class AppModule { }
