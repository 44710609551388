<div class="row">
    <div class="col-md-12 mb-4" *ngIf="auth['data'].role == 1">
        <div class="box-newempresa">
            <div class="title-empresa">Crear nueva empresa</div>
            <div class="row" id="form-empresa">
                <div class="col-md-4 mb-2">
                    <input class="form-control" type="text" placeholder="Razón Social" [(ngModel)]="addEmpresa.razon_social"/>
                </div>
                <div class="col-md-4 mb-2">
                    <input class="form-control" type="text" placeholder="RUT" [(ngModel)]="addEmpresa.rut"/>
                </div>
                <div class="col-md-4 mb-2">
                    <input class="form-control" type="email" placeholder="E-mail" [(ngModel)]="addEmpresa.email"/>
                </div>
                <div class="col-md-4 mb-2">
                    <input class="form-control" type="text" placeholder="Nombre representante legal" [(ngModel)]="addEmpresa.nombre_rl"/>
                </div>
                <div class="col-md-4 mb-2">
                    <input class="form-control" type="text" placeholder="RUT representante legal" [(ngModel)]="addEmpresa.rut_rl"/>
                </div>
                <div class="col-md-4 mb-2">
                    <input class="form-control" type="text" placeholder="Teléfono representante legal" [(ngModel)]="addEmpresa.telefono_rp"/>
                </div>
                <button class="btn btn-block btn-box mb-2" (click)="newEmpresa()">Crear nueva empresa</button>
            </div>
        </div>
    </div>

    <!--<div class="col-md-12 mt-4"><h2>Modificar usuarios en empresas</h2></div>
    <div class="col-md-12 mb-4">
        <div class="box-newempresa">
            <div class="title-empresa">Asignar usuario a una empresa</div>
            <div class="row" id="form-empresa">
                <div class="col-md-4 mb-2">
                    <select class="custom-select" [(ngModel)]="addEmpleados.id_usuario">
                        <option [value]="'selected'" disabled>Seleccione un empleado</option>
                        <option [value]="empleado.id" *ngFor="let empleado of empleados">{{empleado.nombre}}</option>
                    </select>
                </div>
                <div class="col-md-4 mb-2">
                    <select class="custom-select" [(ngModel)]="addEmpleados.id_empresa">
                        <option [value]="'selected'" disabled>Seleccione una empresa</option>
                        <option [value]="empresa.id" *ngFor="let empresa of empresas">{{empresa.name}}</option>
                    </select>
                </div>
                <div class="col-md-4 mb-2">
                    <select class="custom-select" [(ngModel)]="addEmpleados.ejecutivo">
                        <option value="0">Asesor</option>
                        <option value="1">Ejecutivo</option>
                    </select>
                </div>
                <button class="btn btn-block btn-box mb-2" (click)="addUserEmpres()">Asignar usuario</button>
            </div>
        </div>
    </div>

    <div class="mb-4" [ngClass]="{'mb-4 col-md-4': this.empresas.length <= 3, 'mb-4 col-md-3': this.empresas.length > 3}" *ngFor="let grupo of empleadosEmpresa; let i = index">
        <div class="box-empresa mb-3">
            <div class="title-empresa">{{grupo[0].name}}</div>
            <div class="scroll-box">
                <div class="user mb-2 d-flex" *ngFor="let empleadoEmpresa of grupo; let i = index">
                    <div class="box-username"><i class="bi bi-award-fill" *ngIf="empleadoEmpresa.ejecutivo == 1"></i> {{empleadoEmpresa.username}}</div>
                    <div class="box-username delete"><i class="bi bi-trash-fill" (click)="deleteEmpleadoEmpresa(empleadoEmpresa)"></i></div>
                </div>
            </div>
        </div>
    </div>-->
</div>
<div class="row mt-4">
    <div class="col-md-12"><h2>Modifica tus empresas</h2></div>
    <div class="mb-4" [ngClass]="{'mb-4 col-md-12': this.allempresas.length == 1, 'mb-4 col-md-6': this.allempresas.length <3, 'mb-4 col-md-4': this.allempresas.length >= 3}" *ngFor="let e of allempresas; let i = index">
        <div class="box-empresa">
            <div class="title-empresa">{{e.razon_social}}</div>
            <div class="row" id="form-empresa">
                <div class="col-md-6 mb-2">
                    <div class="form-group">
                        <label for="email">E-mail</label>
                        <input class="form-control" id="email" type="email" placeholder="Email" [(ngModel)]="e.email" [disabled]="!e.modify"/>
                    </div>
                </div>
                <div class="col-md-6 mb-2">
                    <div class="form-group">
                        <label for="rut">RUT</label>
                        <input class="form-control" id="rut" type="text" placeholder="RUT" [(ngModel)]="e.rut" [disabled]="!e.modify"/>
                    </div>
                </div>
                <div class="col-md-6 mb-2">
                    <div class="form-group">
                        <label for="rs">Razón social</label>
                        <input class="form-control" id="rs" type="text" placeholder="Razón social" [(ngModel)]="e.razon_social" [disabled]="!e.modify"/>
                    </div>
                </div>
                <div class="col-md-6 mb-2">
                    <div class="form-group">
                        <label for="representantenombre">Nombre representante legal</label>
                        <input class="form-control" id="representantenombre" type="text" placeholder="Nombre representante legal" [(ngModel)]="e.nombre_rl" [disabled]="!e.modify"/>
                    </div>
                </div>
                <div class="col-md-6 mb-2">
                    <div class="form-group">
                        <label for="rutrl">RUT Representante</label>
                        <input class="form-control" id="rutrl" type="text" placeholder="RUT Representante legal" [(ngModel)]="e.rut_rl" [disabled]="!e.modify"/>
                    </div>
                </div>
                <div class="col-md-6 mb-2">
                    <div class="form-group">
                        <label for="trl">Teléfono representante legal</label>
                        <input class="form-control" id="trl" type="tel" placeholder="Teléfono representante legal" [(ngModel)]="e.telefono_rp" [disabled]="!e.modify"/>
                    </div>
                </div>
                <div class="col-md-8 mb-2" *ngIf="auth['data'].role == 1">
                    <button class="btn btn-block btn-personalized btn-sm" (click)="modifyEmpresa(e, i)" *ngIf="e.texto == null">Modificar empresa</button>
                    <button class="btn btn-block btn-personalized btn-sm" (click)="modifyEmpresa(e, i)" *ngIf="e.texto != null">{{e.texto}}</button>
                </div>
                <div class="col-md-4 mb-2" *ngIf="auth['data'].role == 1">
                    <button class="btn btn-block btn-danger btn-sm" (click)="deleteEmpresa(e)">Eliminar</button>
                </div>
            </div>
        </div>
    </div>
</div>