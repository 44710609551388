<div class="top-header">
  <div class="box">
    <div class="logo">
      <img src="https://agasdata.cl/wp-content/uploads/2021/08/Logo-agasdata-1.png" alt="logo">
    </div>
    <div class="options">
      <ul>
        <li>Prueba</li>
        <li>Prueba1</li>
        <li>Prueba2</li>
      </ul>
    </div>
  </div>
</div>
<div [ngClass]="{'left-navbar': this.open == true, 'left-mobile-navbar': this.open == false}">
  <nav class="nav-menu" [ngClass]="{'left-mobile-navbarrp': this.mobile == true && this.open == true}">
    <div class="box-icons">
      <div class="boxicon" *ngIf="this.open == true"><i class="bi bi-person-lines-fill"></i></div>
      <div class="boxicon" *ngIf="this.open == true"><i class="bi bi-hdd-fill"></i></div>
      <div class="boxicon" *ngIf="this.open == true"><i class="bi bi-gear-wide-connected"></i></div>
      <div class="boxicon" (click)="openSidebar()"><i class="bi" [ngClass]="{'bi-arrow-bar-left': this.open == true, 'bi-arrow-bar-right': this.open == false}"></i></div>
    </div>
    <ul>
      <li [routerLink]="['/perfil/1']"><i class="bi bi-hdd-fill" *ngIf="this.open == false"></i><span *ngIf="this.open == true"><i class="bi bi-hdd-fill"></i> Prueba</span></li>
      <li><i class="bi bi-hdd-fill" *ngIf="this.open == false"></i><span *ngIf="this.open == true"><i class="bi bi-hdd-fill"></i> Prueba</span></li>
      <li><i class="bi bi-hdd-fill" *ngIf="this.open == false"></i><span *ngIf="this.open == true"><i class="bi bi-hdd-fill"></i> Prueba</span></li>
    </ul>
  </nav>
  <div class="blur-bg" *ngIf="this.mobile == true && this.open == true"></div>
  <div class="content-main">
    <div class="box-content">
      <div class="content-text">HOLA</div>
    </div>
  </div>
</div>
