import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Empresa } from '../models/empresa';
import { Authrol } from '../interfaces/auth/authrol.interface';

@Injectable({
  providedIn: 'root'
})
export class EmpresaService {
  private empresas$ = new BehaviorSubject<Empresa[]>([]);
  private permisos$ = new BehaviorSubject<Authrol[]>([]);

  setEmpresas(empresas: Empresa[]) {
    this.empresas$.next(empresas);
  }
  
  getEmpresas() {
    return this.empresas$.asObservable();
  }


  setPermisos(permisos: Authrol[]) {
    this.permisos$.next(permisos);
  }
  
  getPermisos() {
    return this.permisos$.asObservable();
  }

  constructor() {}
}
