import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class UploadService {
  public url: string;
  public upload_img: string;

  constructor(public Http: HttpClient) {
      this.upload_img = 'https://agasdata.cl/plataforma/api'
      //this.upload_img = 'https://wintxcoders.com/apirest';
  }

  uploadFile(archivo) {
    return this.Http.post(this.upload_img + '/upload.php', JSON.stringify(archivo));
  }

}
