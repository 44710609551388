import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, CanDeactivate, CanLoad, Route, UrlSegment, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { tap, map } from 'rxjs/operators';
import { User } from '../models/user';


@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanLoad {

  constructor(private authService: AuthService, private router:Router) {

  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {  
      return this.authService.verifyAuth()
      .pipe(
        tap( estaAutenticado => {
          if(!estaAutenticado) {
            this.router.navigate(['./login']);
          } else if (state.url == '/documentos' && (this.authService.authrole['data'][0].permisos == '2' || this.authService.authrole['data'][0].permisos == '1')) {
            return true;
          } else if(state.url == '/rendicion' && this.authService.authrole['data'][0].permisos == '2' || this.authService.authrole['data'][0].permisos == '1') {
            return true;
          } else if(state.url == '/proyectos' && this.authService.authrole['data'][0].permisos == '1' || this.authService.authrole['data'][0].permisos == '0') {
            return true;
          } else if(state.url == '/usuarios' && this.authService.authrole['data'][0].permisos == '1' || this.authService.authrole['data'][0].permisos == '0') {
            return true;
          }
          else if(state.url == '/home' || state.url == '/perfil' || state.url == '/proyectos') {
            return true;
          }

        }
        )
      )

    /*console.log('Bloqueado por AuthGuard');
    return false;*/
  }
  /*canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }
  canDeactivate(
    component: unknown,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }*/
  canLoad(
    route: Route,
    segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean  {

      console.log('candLoad', true);
      console.log(route);
      console.log(segments);
    return false;
  }
}
