<form (ngSubmit)="uploadHeader()">
    <div class="row">
        <div class="col-md-12" *ngIf="documento.fecha_emision != null && documento.nombre != null">
            <div class="alert alert-success" role="alert">
                <i class="bi bi-file-earmark-pdf-fill"></i> Documento listo para subir {{nombreedit || nombre}}
            </div>
        </div>
        <div class="col-md-12 mb-2">
            <select class="custom-select" name="tipo_documentos" [(ngModel)]="documento.tipo_documentos">
                <option [value]="'selected'" selected disabled>Seleccione el tipo de documento que desea adjuntar</option>
                <option [value]="0" (click)="tipeSelect()">CARPETA TRIBUTARIA PARA SOLICITAR CRÉDITOS</option>
                <option [value]="1" (click)="tipeSelect()">POSTULACIÓN</option>
                <option [value]="2" (click)="tipeSelect()">CONTRATO CON BENEFICIARIO</option>
                <option [value]="3" (click)="tipeSelect()">ANEXO DE CONTRATO CON BENEFICIARIO</option>
                <option [value]="4" (click)="tipeSelect()">FORMULARIO F 30</option>
                <option [value]="5" (click)="tipeSelect()">CERTIFICADO DE DEUDA </option>
                <option [value]="6" (click)="tipeSelect()">CÉDULA DE IDENTIDAD REPRESENTANTE LEGAL</option>
                <option [value]="7" (click)="tipeSelect()">ESTATUTOS O ESCRITURA DE LA EMPRESA ACTUALIZADOS</option>
                <option [value]="8" (click)="tipeSelect()">CERTIFICADO DE VIGENCIA DE LA EMPRESA</option>
                <option [value]="9" (click)="tipeSelect()">DECLARACIÓN JURADA SIMPLE DE PROBIDAD</option>
                <option [value]="10" (click)="tipeSelect()">DECLARACIÓN JURADA SIMPLE DE CONSANGUINIEDAD</option>
                <option [value]="11" (click)="tipeSelect()">DECLARACIÓN JURADA SIMPLE DE COMPROMISO Y ENTREGA DE INFORMACIÓN</option>
                <option [value]="12" (click)="tipeSelect()">ANEXO 1</option>
                <option [value]="13" (click)="tipeSelect()">ANEXO 2</option>
                <option [value]="14" (click)="tipeSelect()">ANEXO 3</option>
            </select>
        </div>
        <div class="col-md-12 mb-2">
            <select class="custom-select" name="id_empresa" [(ngModel)]="documento.id_empresa">
                <option [value]='"selected"' selected disabled>Seleccione empresa</option>
                <option [value]="e.id" *ngFor="let e of empresas; let i = index">{{e.razon_social}}</option>
            </select>
        </div>
        <div class="col-md-6">
            <input class="form-control mb-2" type="text" name="nombre" placeholder="Nombre" [value]="nombre" [(ngModel)]="documento.nombre"/>
            <textarea placeholder="Descripción" class="form-control" name="descripcion" [(ngModel)]="documento.descripcion" disabled></textarea>
        </div>
        <div class="col-md-6">
            <div class="d-flex right-box">
                <div class="start-date">
                    <input type="date" class="form-control" name="fecha_emision" [(ngModel)]="documento.fecha_emision"/>
                </div>
                <div class="end-date">
                    <input type="date" class="form-control" name="fecha_termino" [(ngModel)]="documento.fecha_termino" [disabled]="documento.tipo_documentos != 2 && documento.tipo_documentos != 3 && documento.tipo_documentos != 8"/>
                </div>
                <div class="custom-file mt-2">
                    <input type="file" id="file" class="custom-file-input" (change)="seleccionarArchivoHeader($event)" required lang="es">
                    <label class="custom-file-label" for="file">{{nombre || uploadDocument}}</label>
                </div>
            </div>
        </div>
        <div class="col-md-12 mt-4">
            <button class="btn btn-block btn-document"><i class="bi bi-file-earmark-pdf-fill"></i> Subir documento</button>
        </div>
    </div>
</form>


<div class="box-documents">
    <h1>Listado de documentos</h1>
    <div class="document-list">
        <input type="text" class="search" name="filterDocuments" placeholder="Buscar..." [(ngModel)]="filterDocuments">
        <div class="navbar-search d-flex">
            <div class="box-search-documents">Nombre</div>
            <div class="box-search-documents">Empresa</div>
            <div class="box-search-documents">Fecha subida</div>
        </div>
        <div class="scroll-box">
            <div class="document d-flex" *ngFor="let document of getDocuments | filter:filterDocuments" (click)="openModal(document, content)">
                <div class="box-search-documents">
                    {{document.nombre}}
                </div>
                <div class="box-search-documents">
                    {{document.name}}
                </div>
                <div class="box-search-documents">
                    {{document.fecha_subida}}
                </div>
            </div>
        </div>
    </div>
</div>


<!-- Ventana Modal -->
<ng-template #content let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">{{documentModal.nombre}}</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <form>
        <div class="form-group">
          <label>{{documentModal.descripcion}}</label>
          <hr>
            <img class="doc-img" *ngIf="typeimg == 'jpg' || typeimg == 'png' || typeimg == 'jpeg'" [src]="'assets/'+documentModal.ruta" alt="imagen documento"/>
            <ngx-extended-pdf-viewer *ngIf="typeimg == 'pdf'" [src]="'assets/'+documentModal.ruta" useBrowserLocale="true" height="80vh"></ngx-extended-pdf-viewer>
        </div>
      </form>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-danger" (click)="removeDocument()">Eliminar documento</button>
        <button type="button" class="btn btn-success" (click)="donwload()">Descargar archivo</button>
    </div>
  </ng-template>
  
