<div class="empleados">
    <input type="text" class="search" name="filterUsers" placeholder="Buscar..." [(ngModel)]="filterUsers">
    <div class="empleado" *ngFor="let empleado of empleadosall | filter:filterUsers" (click)="open(content, empleado)">
        {{empleado.username}}
    </div>
</div>

<!-- Ventana Modal -->
<ng-template #content let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">{{empleado.username}}</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-6 mb-3">
                <label for="username">Username</label>
                <input id="username" class="form-control" type="text" placeholder="Username" [(ngModel)]="empleado.username" disabled/>
            </div>
            <div class="col-md-6 mb-3">
                <label for="nombre_username">Nombre</label>
                <input id="nombre_username" class="form-control" type="text" placeholder="Nombre" [(ngModel)]="empleado.nombre" [disabled]="!disabled"/>
            </div>
            <div class="col-md-6 mb-3">
                <label for="apellidos_username">Apellidos</label>
                <input id="apellidos_username" class="form-control" type="text" placeholder="Apellidos" [(ngModel)]="empleado.apellidos" [disabled]="!disabled"/>
            </div>
            <div class="col-md-6 mb-3">
                <label for="fecha_nacimiento">Fecha nacimiento</label>
                <input id="fecha_nacimiento" class="form-control" placeholder="00/00/0000" type="date" [(ngModel)]="empleado.fecha_nacimiento" [disabled]="!disabled"/>
            </div>
            <div class="col-md-6 mb-3">
                <label for="email">E-mail</label>
                <input id="email" class="form-control" placeholder="E-mail" type="email" [(ngModel)]="empleado.email" [disabled]="!disabled"/>
            </div>
            <div class="col-md-6 mb-3">
                <label for="region">Región</label>
                <input id="region" class="form-control" placeholder="Región" type="text" [(ngModel)]="empleado.region" [disabled]="!disabled"/>
            </div>
            <div class="col-md-6 mb-3">
                <label for="tipouser">Tipo de usuario</label>
                <select id="tipouser" [(ngModel)]="empleado.role" class="custom-select" [disabled]="!disabled">
                    <option value="0">Usuario básico</option>
                    <option value="1">Usuario Administrador</option>
                </select>
            </div>
            <div class="col-md-6 mb-3">
                <label for="activo">Usuario activo</label>
                <select id="activo" [(ngModel)]="empleado.activo" class="custom-select" [disabled]="!disabled">
                    <option value="1">Usuario activo</option>
                    <option value="0">Usuario inactivo</option>
                </select>
            </div>
            <div class="col-md-12 mb-1">
                <button class="btn btn-block btn-info" (click)="openModal(contentpassword)">Cambiar contraseña</button>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-danger-users" (click)="deleteUser()">Eliminar empleado</button>
        <button type="button" class="btn btn-success" (click)="activateModify()">Modificar usuario</button>
    </div>
</ng-template>

<!-- Ventana Modal -->
<ng-template #contentpassword let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Cambiar contraseña</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="form" (ngSubmit)="submit(content)">
            <div class="change-password">
                <div *ngIf="f.repeatpassword.touched && f.repeatpassword.invalid" class="alert alert-danger mb-2">
                    <div *ngIf="f.repeatpassword.errors.required">La contraseña es requerida.</div>
                    <div *ngIf="f.repeatpassword.errors.confirmedValidator">Las contraseñas no coinciden.</div>
                </div>
                <input type="password" formControlName="newpassword" class="form-control" placeholder="Nueva contraseña" name="password"/>
                <input type="password" formControlName="repeatpassword" class="form-control mt-2" placeholder="Repetir contraseña" name="repeatpassword"/>
                <button class="btn btn-block btn-sm btn-info mt-3" type="submit" [disabled]="!form.valid">Cambiar contraseña</button>
            </div>
        </form>
    </div>
</ng-template>