<div class="top-header">
  <div class="box">
    <div class="logo">
      <img src="https://agasdata.cl/wp-content/uploads/2021/08/Logo-agasdata-1.png" alt="logo">
    </div>
    <div class="options">
      <ul>
        <li (click)="logout()" *ngIf="router.url !== '/'"><span class="salir">SALIR </span> <i class="bi bi-x-circle-fill"></i></li>
        <!--<li>Prueba2</li>-->
      </ul>
    </div>
  </div>
</div>

<div class="login-box" *ngIf="router.url === '/login' || router.url === '/'">
  <app-login></app-login>
</div>

<div [ngClass]="{'left-navbar': this.open == true, 'left-mobile-navbar': this.open == false}" *ngIf="router.url !== '/'">
  <nav class="nav-menu" [ngClass]="{'left-mobile-navbarrp': this.mobile == true && this.open == true}">
    <div class="box-icons">
      <div class="boxicon" *ngIf="this.open == true" routerLink='/perfil' routerLinkActive='active'><i class="bi bi-person-lines-fill"></i></div>
      <div class="boxicon" *ngIf="this.open == true && authrole['data'][0].permisos != 0 || auth['data'].role == 1 && this.open == true" routerLink='/empresa' routerLinkActive='active'><i class="bi bi-building"></i></div>
      <!--<div class="boxicon" *ngIf="this.open == true"><i class="bi bi-gear-wide-connected"></i></div>-->
      <div class="boxicon" (click)="openSidebar()"><i class="bi" [ngClass]="{'bi-arrow-bar-left': this.open == true, 'bi-arrow-bar-right': this.open == false}"></i></div>
    </div>
    <ul>
      <li routerLink='/home' routerLinkActive='active'><i class="bi bi-house-fill" *ngIf="this.open == false"></i><span *ngIf="this.open == true"><i class="bi bi-house-fill"></i> Inicio</span></li>
      <!--<li routerLink='/documentos' routerLinkActive='active' ><i class="bi bi-file-earmark-pdf-fill" *ngIf="this.open == false"></i><span *ngIf="this.open == true"><i class="bi bi-file-earmark-pdf-fill"></i> Documentos</span></li>-->
      <li routerLink='/usuarios' routerLinkActive='active' *ngIf="auth['data'].role == 1"><i class="bi bi-people-fill" *ngIf="this.open == false"></i><span *ngIf="this.open == true"><i class="bi bi-people-fill"></i> Usuarios</span></li>
      <li routerLink='/rendicion' routerLinkActive='active'><i class="bi bi-file-earmark-bar-graph-fill" *ngIf="this.open == false"></i><span *ngIf="this.open == true"><i class="bi bi-file-earmark-bar-graph-fill"></i> Rendicion</span></li>
      <li routerLink='/proyectos' routerLinkActive='active'><i class="bi bi-file-earmark-bar-graph-fill" *ngIf="this.open == false"></i><span *ngIf="this.open == true"><i class="bi bi-file-earmark-bar-graph-fill"></i> Proyectos</span></li>    
    </ul>
    <div class="marca-agua" *ngIf="this.mobile == false"><a target="_blank" href="https://webcreativo.cl/">© WebCreativo</a></div>

    </nav>
  <div class="blur-bg" *ngIf="this.mobile == true && this.open == true"></div>
  <div class="content-main">
    <div class="box-content">
      <div class="content-text">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>
