import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { EmpresaService } from '../services/empresa.service';
import { Empresa } from '../models/empresa';
import { AppService } from '../services/app.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-empresa',
  templateUrl: './empresa.component.html',
  styleUrls: ['./empresa.component.scss']
})
export class EmpresaComponent implements OnInit {

  get auth() {
    return this.authService.auth;
  }

  public empresas : Empresa[];
  public empresaMo: Empresa;
  public addEmpresa: Empresa;
  public empresasid: any;
  public data: any;
  public active = new Array();
  public empleados;
  public empleadosEmpresa = new Array();
  public allempresas : Empresa[];
  public empresaModify: any;
  public empresatextModify: string;
  public addEmpleados = {
    id_empresa: 'selected',
    id_usuario: 'selected',
    ejecutivo: 0
  }

  public proyectosArray = {
    id_empresa: [],
    name: '',
    tipo_documentos: '',
    tipo: '',
    length
  }

  public proyectosfinal = new Array();

  constructor(private router: Router, private authService: AuthService, private empresaService: EmpresaService, private appService: AppService, private toastr: ToastrService) {
    this.empresaMo = new Empresa();
    this.addEmpresa = new Empresa();
    this.proyectosArray = null;
    this.proyectosfinal = [];
  }

  ngOnInit(): void {
    this.loadEmpresas();
    this.obtenerEmpleados();
    this.getEmpleadosEmpresa();
    this.getallEmpresa();
  }

  loadEmpresas() {
    this.empresaService.getEmpresas().subscribe(empresas => this.empresas = empresas);
  }

  getEmpresa() {
    this.authService.getEmpresa(this.auth['data'].id).subscribe(
      response => {
        if(response['code'] == 200) {
          this.empresaService.setEmpresas(response['data']);
        }
      },
      error => {
        console.log(<any>error);
      }
    );
  }

  deleteEmpresa(e) {
    if(this.auth['data'].role == 1) {
      this.appService.deleteEmpresa(e.id).subscribe(
        response => {
          if(response['code'] == 200) {
            this.toastr.success('Éxito al eliminar', 'Empresa eliminada');
            this.getEmpresa();
            this.getallEmpresa();
            this.obtenerEmpleados();
            this.getEmpleadosEmpresa();
          } else {
            this.toastr.error('Error al eliminar', 'Empresa no eliminada');
          }
        },
        error => {
          console.log(<any>error);
        }
      );
    }
  }

  addUserEmpres() {
    if(this.auth['data'].role == 1) {
      this.appService.addUserEmpresa(this.addEmpleados).subscribe(
        response => {
          if(response['code'] == 200) {
            this.empleados = response['data'];
            this.toastr.success('Éxito al asignar', 'Empleado asignado');
            this.obtenerEmpleados();
            this.getEmpleadosEmpresa();
          } else {
            this.toastr.error('Error al asignar', 'Empleado no asignado');
          }
        },
        error => {
          console.log(<any>error);
        }
      );
    }
  }

  newEmpresa() {
    if(this.auth['data'].role == 1) {
      this.addEmpresa.activo = '0';
      this.appService.addnewEmpresa(this.addEmpresa).subscribe(
        response => {
          if(response['code'] == 200) {
            this.addEmpleados.ejecutivo = 1;
            this.addEmpleados.id_usuario = this.auth['data'].id;
            
            this.appService.getEmpresa().subscribe(
              response => {
                if(response['code'] == 200) {
                  this.empresasid = response['data'];
                  this.empresasid= this.empresasid[this.empresasid.length - 1];
                  this.addEmpleados.id_empresa = this.empresasid.id;
                  this.appService.addUserEmpresanew(this.addEmpleados).subscribe(
                    response => {
                      if(response['code'] == 200) {
                        this.empleados = response['data'];
                        this.toastr.success('Éxito al crear', 'Empresa creada');
                        this.getEmpresa();
                        this.getallEmpresa();
                        this.obtenerEmpleados();
                        this.getEmpleadosEmpresa();
                        this.addEmpresa = new Empresa();
                      } else {
                        this.toastr.error('Error al crear', 'Empresa no creada');
                      }
                    },
                    error => {
                      console.log(<any>error);
                    }
                  );
                } else {
                  this.toastr.error('Error al crear', 'Empresa no creada');
                }
              },
              error => {
                console.log(<any>error);
              }
            );
          } else {
            this.toastr.error('Error al crear', 'Empresa no creada');
          }
        },
        error => {
          console.log(<any>error);
        }
      );
    }
  }

  modifyEmpresa(e, i) {
    if(this.auth['data'].role == 1) {
      if(e.modify != true) {
        e.texto = 'Guardar cambios';
        e.modify = true;
      } else {
        e.modify = false;
        e.texto = 'Modificar empresa';
        this.empresaMo.id = e.id;
        this.empresaMo.email = e.email;
        this.empresaMo.rut = e.rut;
        this.empresaMo.razon_social = e.razon_social;
        this.empresaMo.nombre_rl = e.nombre_rl;
        this.empresaMo.rut_rl = e.rut_rl;
        this.empresaMo.telefono_rp = e.telefono_rp;
        this.empresaMo.activo = e.activo;

        this.appService.updateEmpresa(this.empresaMo.id, this.empresaMo).subscribe(
          response => {
            if(response['code'] == 200) {
              this.getallEmpresa();
              this.toastr.success('Éxito al actualizar', 'Empresa actualizada');
            } else {
              this.toastr.error('Error al actualizar', 'Empresa no actualizada');
            }
          },
          error => {
            console.log(<any>error);
          }
        );
      }
    }   
  }

  obtenerEmpleados() {
    this.appService.getEmpleados(this.auth['data'].id).subscribe(
      response => {
        if(response['code'] == 200) {
          this.empleados = response['data'];
        } else {
          console.log('Error en la petición');
        }
      },
      error => {
        console.log(<any>error);
      }
    );
  }

  deleteEmpleadoEmpresa(empleadoEmpresa) {
    if(this.auth['data'].role == 1) {
      this.appService.deleteEmpleadoEmpresa(empleadoEmpresa.id).subscribe(
        response => {
          if(response['code'] == 200) {
            this.toastr.success('Éxito al eliminar', 'Empleado eliminado');
            this.getEmpleadosEmpresa();
          } else {
            this.toastr.error('Error al eliminar', 'Empleado no eliminado');
          }
        },
        error => {
          console.log(<any>error);
        }
      );
    }
  }

  getEmpleadosEmpresa() {
    setTimeout(() => {
      for(let i = 0; i < this.empresas.length; i++) {
        this.appService.getEmpleadosEmpresa(this.empresas[i].id).subscribe(
          response => {
            if(response['code'] == 200) {
              this.empleadosEmpresa[i] = response['data'];
              if(response['data'][i].razon_social != '') {
                this.empleadosEmpresa[i].razon_social = response['data'][i].name;
              }
              
            } else {
              console.log('Error en la petición');
            }
          },
          error => {
            console.log(<any>error);
          }
        );
      }
    }, 600);
  }

  // Obtenemos todas las empresas creadas
  getallEmpresa() {
    if(this.auth['data'].role == 1) {
      this.authService.getAllEmpresas().subscribe(
        response => {
          if(response['code'] == 200) {
            this.allempresas = response['data'];
          }
        },
        error => {
          console.log(<any>error);
        }
      );
    } else if(this.auth['data'].role == 0) {
      // Obtenemos los proyectos asignados a un usuario
      this.authService.getUserProyect(this.auth['data'].id).subscribe(
        response => {
          if(response['code'] == 200) {
            this.proyectosArray = response['data'];
            for(let i = 0; i < response['data'].length; i++) {
              if(this.proyectosArray[i].id_empresa[0] != '' && this.proyectosfinal.length == 0) {
                this.proyectosfinal = this.proyectosArray[i].id_empresa.split(',');
              } else if(this.proyectosArray[i].id_empresa[0] != '' && this.proyectosfinal.length >= 1) {
                let test = this.proyectosArray[i].id_empresa.split(',');
                if(test.length > 1) {
                  this.proyectosfinal.push(this.proyectosArray[i].id_empresa.split(','));
                } else {
                  this.proyectosfinal.push(this.proyectosArray[i].id_empresa);
                }
              }
            }
            

            this.appService.getEmpresaProyecto(this.proyectosfinal).subscribe(
              response => {
                if(response['code'] == 200) {
                  this.allempresas = response['data'];
                  console.log(this.allempresas);
                }
              },
              error => {
                console.log(<any>error);
              }
            );


          }
        },
        error => {
          console.log(<any>error);
        }
      );
    }
  } 
}
