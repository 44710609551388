<h1>{{auth['data'].username}}</h1>

<div class="row">
  <div class="col-md-6" *ngFor="let e of empresas; let i = index">
    <div class="box-empresa">
      <div class="title-empresa">{{e.razon_social}}</div>
      <ul>
        <li><span>Email</span> - {{e.email}}</li>
        <li><span>RUT</span> - {{e.rut}}</li>
        <li><span>RAZÓN SOCIAL</span> - {{e.razon_social}}</li>
        <li><span>NOMBRE REPRESENTANTE</span> - {{e.nombre_rl}}</li>
        <li><span>RUT REPRESENTANTE</span> - {{e.rut_rl}}</li>
        <li><span>TELÉFONO REPRESENTANTE</span> - {{e.telefono_rp}}</li>
        <li><span>DATOS EMPRESA</span> - {{e.datos_empresa}}</li>
        <!--<li [ngClass]="{'active':  this.active[i] == 0, 'desactive': this.active[i] == 1}"><span>{{e.activo}}</span></li>-->
        <li *ngIf="e.activo == 0" class="active">Activa</li>
        <li *ngIf="e.activo == 1" class="desactive">Desactivada</li>
      </ul>
    </div>
  </div>
</div>