import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { AppService } from '../services/app.service';
import { ToastrService } from 'ngx-toastr';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, FormControl, Validators} from '@angular/forms';
import { passwordVerify } from './confirmpassword.validators';
import { User } from '../models/user';


@Component({
  selector: 'app-usuarios',
  templateUrl: './usuarios.component.html',
  styleUrls: ['./usuarios.component.scss']
})
export class UsuariosComponent implements OnInit {

  get auth() {
    return this.authService.auth;
  }
  public empleadosall;
  public filterUsers;
  public disabled: boolean = false;
  public empleado = new User;
  form: FormGroup = new FormGroup({});

  constructor(    
    private router: Router, 
    private authService: AuthService, 
    private appService: AppService, 
    private toastr: ToastrService,
    private modalService: NgbModal,
    private fb: FormBuilder) {
      this.filterUsers = '';
      this.form = fb.group({
        newpassword: ['', [Validators.required]],
        repeatpassword: ['', [Validators.required]]
      }, { 
        validator: passwordVerify('newpassword', 'repeatpassword')
      })
    }

  ngOnInit(): void {
    this.getallUsers();
  }

  // Obtenemos los proyectos asignados
  getallUsers() {
    if(this.auth['data'].role == 1) {
      this.appService.getAllEmpleados().subscribe(
        response => {
          if(response['code'] == 200) {
            this.empleadosall = response['data'];
          } else {
            console.log('Error en la petición');
          }
        },
        error => {
          console.log(<any>error);
        }
      );
    } else {
      this.toastr.error('Permisos insuficientes', 'No tienes permisos');
    }
  }

  // Eliminar usuario
  deleteUser() {
    if(this.auth['data'].role == 1) {
      this.authService.deleteEmpleado(this.empleado.id).subscribe(
        response => {
          if(response['code'] == 200) {
            this.getallUsers();
            this.modalService.dismissAll();
            this.toastr.success('Empleado eliminado', 'Éxito al eliminar');
          } else {
            this.toastr.error('Empleado no eliminado', 'Error al eliminar');
          }
        },
        error => {
          console.log(<any>error);
        }
      );
    } else {
      this.toastr.error('Empleado no eliminado', 'Error al eliminar');
    }
  }

  // Abrimos la modal
  open(content, empleado) {
    this.disabled = false;
    this.empleado = empleado;
    this.modalService.open(content, { size: 'xl' });
  }

  // Abrimos la modal para cambiar la password
  openModal(contentpassword) {
    this.modalService.open(contentpassword, { size: 'xs' });
  }

  get f(){
    return this.form.controls;
  }

  submit(content){
    if(this.auth['data'].role == 1) {
      this.authService.updatePassword(this.empleado.id, this.form.value.newpassword).subscribe(
        response => {
          if(response['code'] == 200) {
            this.getallUsers();
            this.disabled = false;
            this.modalService.dismissAll();
            this.open(content, this.empleado)
            this.toastr.success('Contraseña actualizada', 'Éxito al actualizar');
          } else {
            this.toastr.error('Contraseña no actualizada', 'Error al actualizar');
          }
        },
        error => {
          console.log(<any>error);
        }
      );
    } else {
      this.toastr.error('Empleado no eliminado', 'Error al eliminar');
    }
  }

  activateModify() {
    if(this.disabled == false) {
      this.disabled = true;
    } else {
      this.modifyUser();
      this.disabled = false;
    }
  }

  modifyUser() {
    this.appService.modifyUser(this.empleado.id, this.empleado).subscribe(
      response => {
        if(response['code'] == 200) {
          console.log('Actualizado con éxito');
          this.disabled = false;
          this.getallUsers();
          this.toastr.success('Éxito al modificar', 'Usuario modificado');
        } else {
          console.log('Error en la petición');
          this.toastr.error('Error al modificar', 'Usuario no modificado');
        }
      },
      error => {
        console.log(<any>error);
      }
    );
  }
}
