<div class="proyect">
    <div class="row">
        <div class="col-md-6" *ngIf="this.auth['data'].role == 1">
            <div class="create-proyect">
                <div class="box-newempresa">
                    <div class="title-empresa">Crear nuevo proyecto</div>
                    <div class="form-group">
                        <div class="row">
                            <div class="col-md-4">
                                <input class="form-control mb-2" placeholder="Nombre del proyecto" [(ngModel)]="newProyecto.name"/>
                            </div>                            
                            <div class="col-md-4">
                                <select class="custom-select" [(ngModel)]="newProyecto.tipo">
                                    <option disabled [value]="'3'">Seleccione tipo de proyecto</option>
                                    <option [value]="'1'">SERCOTEC</option>
                                    <option [value]="'0'">CORFO</option>
                                </select>
                            </div>
                            <div class="col-md-4">
                                <ng-select [(ngModel)]="newProyecto.id_empresa" [multiple]="true" [closeOnSelect]="false"
                                [searchable]="true">
                                    <ng-option value='selected' disabled>Seleccione empresa</ng-option>
                                    <ng-option *ngFor="let empresa of allempresas" [value]="empresa.id" placeholder="Selecciona Empresa">{{empresa.razon_social}}</ng-option>
                                </ng-select>
                            </div>
                            <div class="col-md-12">
                                <ng-select [multiple]="true" name="tipo_documentos" [(ngModel)]="newProyecto.tipo_documentos" [closeOnSelect]="false"
                                [searchable]="true">
                                    <ng-option value="selected" disabled>Seleccione documentación</ng-option>
                                    <ng-option [value]="0">CARPETA TRIBUTARIA PARA SOLICITAR CRÉDITOS</ng-option>
                                    <ng-option [value]="1">POSTULACIÓN</ng-option>
                                    <ng-option [value]="2">CONTRATO CON BENEFICIARIO</ng-option>
                                    <ng-option [value]="3">ANEXO DE CONTRATO CON BENEFICIARIO</ng-option>
                                    <ng-option [value]="4">FORMULARIO F 30</ng-option>
                                    <ng-option [value]="5">CERTIFICADO DE DEUDA </ng-option>
                                    <ng-option [value]="6">CÉDULA DE IDENTIDAD REPRESENTANTE LEGAL</ng-option>
                                    <ng-option [value]="7">ESTATUTOS O ESCRITURA DE LA EMPRESA ACTUALIZADOS</ng-option>
                                    <ng-option [value]="8">CERTIFICADO DE VIGENCIA DE LA EMPRESA</ng-option>
                                    <ng-option [value]="9">DECLARACIÓN JURADA SIMPLE DE PROBIDAD</ng-option>
                                    <ng-option [value]="10">DECLARACIÓN JURADA SIMPLE DE CONSANGUINIEDAD</ng-option>
                                    <ng-option [value]="11">DECLARACIÓN JURADA SIMPLE DE COMPROMISO Y ENTREGA DE INFORMACIÓN</ng-option>
                                    <ng-option [value]="12">ANEXO 1</ng-option>
                                    <ng-option [value]="13">ANEXO 2</ng-option>
                                    <ng-option [value]="14">ANEXO 3</ng-option>
                                    <ng-option [value]="15">RENDICIÓN</ng-option>
                                </ng-select>
                            </div>
                        </div>
                        <button class="btn btn-block btn-box mt-3" (click)="addProyect()">Crear proyecto</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-md-6" *ngIf="this.auth['data'].role == 1">
            <div class="add-people">
                <div class="box-newempresa">
                    <div class="title-empresa">Asignar usuario a un proyecto</div>
                    <div class="form-group mt-3">
                        <div class="row">
                            <div class="col-md-12" *ngIf="camposvacios == true">
                                <div class="alert alert-danger" role="alert">
                                    <i class="bi bi-exclamation-circle-fill"></i> Rellena todos los campos
                                </div>
                            </div>
                            <div class="col-md-6">
                                <ng-select [(ngModel)]="asignusertoproyect.id_proyecto">
                                    <ng-option value="selected" disabled>Seleccione proyecto</ng-option>
                                    <ng-option *ngFor="let allproyect of proyectosArray" [value]="allproyect.id" placeholder="Proyectos">{{allproyect.name}}</ng-option>
                                </ng-select>
                            </div>
                            <div class="col-md-6">
                                <ng-select [(ngModel)]="asignusertoproyect.id_usuario">
                                    <ng-option value="selected" disabled>Seleccione empleado</ng-option>
                                    <ng-option *ngFor="let empleado of empleadosall" [value]="empleado.id" placeholder="Personal">{{empleado.username}}</ng-option>
                                </ng-select>
                            </div>
                            <div class="col-md-12 mt-2">
                                <select class="custom-select" [(ngModel)]="asignusertoproyect.rank">
                                    <option value="selected" disabled>Seleccione rango</option>
                                    <option value='0'>Asesor</option>
                                    <option value='1'>Ejecutivo</option>
                                </select>
                            </div>
                            <div class="col-md-12">
                                <button class="btn btn-block btn-box mt-3" (click)="addUsertoProyect()">Agregar personal</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-md-12 mt-4">
            <div class="add-people">
                <div class="box-newempresa">
                    <div class="title-empresa">Proyectos creados</div>
                    <div class="form-group mt-3 proyecto-list">
                        <div class="proyecto" *ngFor="let proyecto of proyectosArray" (click)="openModal(proyecto, content)">
                            {{proyecto.name}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Ventana Modal -->
<ng-template #content let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">{{proyectoModal.name}}</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <form>
        <div class="form-group">
            <input type="text" class="form-control mb-2" placeholder="Nombre del proyecto" name="name" [value]="proyectoModal.name" [(ngModel)]="proyectoModal.name" [disabled]="!editar">
            
            <select class="custom-select mb-2" [(ngModel)]="tipo" name="tipo" [disabled]="!editar">
                <option disabled [value]="'3'">Seleccione tipo de proyecto</option>
                <option [value]="'1'">SERCOTEC</option>
                <option [value]="'0'">CORFO</option>
            </select>

            <ng-select 
            [(ngModel)]="selected" 
            [multiple]="true"
            name="selected"
            [closeOnSelect]="false"
            [searchable]="true"
            [disabled]="!editar">
                <ng-option *ngFor="let empresa of allempresas" value="{{empresa.id}}" name="id" placeholder="Seleccione empresas">{{empresa.razon_social}}</ng-option>
            </ng-select>


            <ng-select [multiple]="true" class="mt-2" name="tipo_documentos" [(ngModel)]="selectedDocumentos" name="selectedDocumentos" [closeOnSelect]="false"
            [searchable]="true" [disabled]="!editar">
                <ng-option [value]="0">CARPETA TRIBUTARIA PARA SOLICITAR CRÉDITOS</ng-option>
                <ng-option [value]="1">POSTULACIÓN</ng-option>
                <ng-option [value]="2">CONTRATO CON BENEFICIARIO</ng-option>
                <ng-option [value]="3">ANEXO DE CONTRATO CON BENEFICIARIO</ng-option>
                <ng-option [value]="4">FORMULARIO F 30</ng-option>
                <ng-option [value]="5">CERTIFICADO DE DEUDA </ng-option>
                <ng-option [value]="6">CÉDULA DE IDENTIDAD REPRESENTANTE LEGAL</ng-option>
                <ng-option [value]="7">ESTATUTOS O ESCRITURA DE LA EMPRESA ACTUALIZADOS</ng-option>
                <ng-option [value]="8">CERTIFICADO DE VIGENCIA DE LA EMPRESA</ng-option>
                <ng-option [value]="9">DECLARACIÓN JURADA SIMPLE DE PROBIDAD</ng-option>
                <ng-option [value]="10">DECLARACIÓN JURADA SIMPLE DE CONSANGUINIEDAD</ng-option>
                <ng-option [value]="11">DECLARACIÓN JURADA SIMPLE DE COMPROMISO Y ENTREGA DE INFORMACIÓN</ng-option>
                <ng-option [value]="12">ANEXO 1</ng-option>
                <ng-option [value]="13">ANEXO 2</ng-option>
                <ng-option [value]="14">ANEXO 3</ng-option>
                <ng-option [value]="15">RENDICIÓN</ng-option>
            </ng-select>

            <div [ngClass]="{'usertoproyectnomodify': !editar, 'usertoproyectmodify': editar}" *ngIf="usertoproyect.length > 0">
                <div class="d-flex userlistproyect" *ngFor="let user of usertoproyect">
                    <div [ngClass]="{'asesor': user.rank == 0, 'ejecutivo': user.rank == 1}">{{user.username}}</div>
                    <div class="options" *ngIf="editar">
                        <i class="bi bi-trash-fill" *ngIf="editar" (click)="deleteUsertoProyect(user.iduserproyect, user.id_usuario)"></i>
                    </div>
                </div>
            </div>

            <div class="add-documents" *ngIf="proyectoModal.tipo_documentos[0].toString() != ''">
                <h4 class="mt-4">Adjuntar documentación</h4>
                <select 
                class="custom-select mt-3 mb-3"
                [(ngModel)]="adddocumentfile" name="empresaselect">
                    <option *ngFor="let empresaSelect of empresasProyectos; let i = index" value="{{empresaSelect.id}}" name="id" placeholder="Seleccione empresas" (click)="changeEmpresa(empresaSelect.id)">{{empresaSelect.razon_social}}</option>
                </select>
                <div class="documentuploads mb-4" *ngFor="let nuberdocument of proyectoModal.tipo_documentos; let i = index">
                    <div class="title-document">{{typeDocument[nuberdocument]}}</div>
                    <div class="alert alert-success mt-3" role="alert" *ngIf="nuberdocument == subidaok[i]">
                        <i class="bi bi-check-lg"></i> Archivo subido con éxito
                    </div>

                    <form (ngSubmit)="uploadHeader(nuberdocument, content)" *ngIf="nuberdocument != subidaok[i]" class="mt-3">
                        <div class="row mt-1">
                            <div class="col-md-12" *ngIf="documento.fecha_emision != null && documento.nombre != null">
                                <div class="alert alert-success" role="alert">
                                    <i class="bi bi-file-earmark-pdf-fill"></i> Documento listo para subir {{nombreedit || nombre}}
                                </div>
                            </div>
                            <div class="col-md-4">
                                <label for="nombre">Nombre de archivo</label>
                                <input id="nombre" class="form-control" type="text" name="nombre" placeholder="Nombre" [value]="nombre" [(ngModel)]="documento.nombre"/>
                            </div>
                            <div class="col-md-4">
                                <div class="start-date">
                                    <label for="fecha_emision">Fecha inicio</label>
                                    <input id="fecha_emision" type="date" class="form-control" name="fecha_emision" [(ngModel)]="documento.fecha_emision"/>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="end-date">
                                    <label for="fecha_termino">Fecha termino</label>
                                    <input id="fecha_termino" type="date" class="form-control" name="fecha_termino" [(ngModel)]="documento.fecha_termino" [disabled]="nuberdocument != 2 && nuberdocument != 3 && nuberdocument != 8"/>
                                </div>
                            </div>
                            <div class="col-md-12 mt-2">
                                <div class="custom-file">
                                    <input type="file" id="file" class="custom-file-input" (change)="seleccionarArchivoHeader($event)" required lang="es">
                                    <label class="custom-file-label" for="file">{{nombre || archivoHeader.nombreArchivo}}</label>
                                </div>
                            </div>
                            <div class="col-md-12 mt-2">
                                <textarea placeholder="Descripción" class="form-control" name="descripcion" [(ngModel)]="documento.descripcion"></textarea>
                            </div>
                            <div class="col-md-12 mt-3">
                                <button class="btn btn-block btn-document"><i class="bi bi-file-earmark-pdf-fill"></i> Subir documento</button>
                            </div>
                        </div>
                    </form>

                    <div class="row" *ngIf="nuberdocument == subidaok[i]">
                        <div class="col-md-12">
                            <button class="btn btn-block btn-sm btn-mostrar-documento" (click)="openDocument(subidaok[i], documentsFilter, contentdocument)">Mostrar documento</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </form>
    </div>
    <div class="modal-footer">
        <button class="btn btn-box" (click)="editarProyecto()" *ngIf="!editar">Editar proyecto</button>
        <button class="btn btn-success" (click)="updateProyecto()" *ngIf="editar">Guardar cambios</button>
        <button class="btn btn-danger" (click)="removeProyecto()" *ngIf="editar">Eliminar proyecto</button>
    </div>
</ng-template>


<!-- Ventana Modal -->
<ng-template #contentdocument let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">{{onlydocument[0].nombre}}</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        <img class="doc-img" *ngIf="typeimg == 'jpg' || typeimg == 'png' || typeimg == 'jpeg'" [src]="'assets/'+onlydocument[0].ruta" alt="imagen documento"/>
        <ngx-extended-pdf-viewer *ngIf="typeimg == 'pdf'" [src]="'assets/'+onlydocument[0].ruta" useBrowserLocale="true" height="80vh"></ngx-extended-pdf-viewer>
    </div>
    <div class="modal-footer">
        <button class="btn btn-sm btn-info" (click)="donwload(onlydocument[0])">Descargar documento</button>
        <button class="btn btn-sm btn-danger-documento" (click)="deleteDocument(onlydocument[0], proyectoModal)">Eliminar documento</button>
    </div>
</ng-template>