import { Component, OnChanges } from '@angular/core';
import { AuthService } from './services/auth.service';
import { Router } from '@angular/router';
import { EmpresaService } from './services/empresa.service';
import { Authrol } from './interfaces/auth/authrol.interface';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnChanges {
  title = 'agasdata';
  public open:boolean;
  public mobile:boolean;
  public authLogin: boolean;
  public permisos;

  get auth() {
    return this.authService.auth;
  }

  get authrole() {
    return this.authService.authrole;
  }


  constructor(private authService: AuthService, public router: Router, private empresaService: EmpresaService) {
    if(window.innerWidth < 1270) {
      this.open = true;
    } else {
      this.open = false;
      this.mobile = true;
    }

    if(window.innerWidth < 778) {
      this.mobile = true;
    } else {
      this.mobile = false;
    }
  }

  ngOnInit(): void {
    this.openSidebar();
  }

  ngOnChanges() {
    this.loadPermisos();
  }

  openSidebar() {
    if(this.open) {
      this.open = false;
    } else {
      this.open = true;
    }
  }

  loadPermisos() {
    this.empresaService.getPermisos().subscribe(permisos => this.permisos = permisos);
  }

  logout() {
    this.authService.logout();
    this.router.navigate(['/']);
  }
}
