import { Component, OnInit } from '@angular/core';
import { UploadService } from '../services/upload.service';
import { FormControl } from '@angular/forms';
import { EmpresaService } from '../services/empresa.service';
import { Empresa } from '../models/empresa';
import { Documento } from '../models/documento';
import { AppService } from '../services/app.service';
import { AuthService } from '../services/auth.service';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import {DomSanitizer} from '@angular/platform-browser';
import { pdfDefaultOptions } from 'ngx-extended-pdf-viewer';

@Component({
  selector: 'app-documentos',
  templateUrl: './documentos.component.html',
  styleUrls: ['./documentos.component.scss']
})
export class DocumentosComponent implements OnInit {
  fileToUpload: File | null = null;
  uploadDocument: string = 'Seleccione un archivo';
  closeResult = '';
  public documento : Documento;
  public empresas : Empresa[];
  public aleatorio: number;
  public getDocuments:any;
  public idEmpresas = new Array();
  public idEmpresasString: string;
  public documentModal: Documento;
  public archivoHeader = {
    nombre: null,
    nombreArchivo: null,
    base64textString: null,
    nombreUsuario: null,
  }
  public nombre: string | null;
  public pdfSource: string;
  public filterDocuments;
  public sanitizedUrl;
  public typeimg;

  get auth() {
    return this.authService.auth;
  }


  constructor(
    fileUploadService:UploadService, 
    private empresaService:EmpresaService, 
    private uploadService:UploadService, 
    private appService:AppService, 
    private authService:AuthService,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private sanitizer:DomSanitizer) {

    this.documento = new Documento;
    this.documento.tipo_documentos = 'selected';
    this.documento.id_empresa = 'selected';
    this.filterDocuments = '';
    pdfDefaultOptions.assetsFolder = 'bleeding-edge';
  }

  ngOnInit(): void {
    this.loadEmpresas();
    this.getDocumentos();
  }

  loadEmpresas() {
    this.empresaService.getEmpresas().subscribe(empresas => this.empresas = empresas);
  }

  tipeSelect() {
    if(this.documento.tipo_documentos == 0) {
      this.documento.descripcion = 'CARPETA TRIBUTARIA PARA SOLICITAR CRÉDITOS / LA CARPETA TRIBUTARIA OARA SOLICITAR CRÉDITOS SE OBTIENE DEL SERVICIO DE IMPUESTOS INTERNOS - SERVICIOS ONLINE - SITUACIÓN TRIBUTARIA - CARPETA TRIBUTARIA ELECTRÓNICA - CARPETA TRIBUTARIA REGULAR - PARA SOLICITAR CRÉDITOS.';
    } else if (this.documento.tipo_documentos == 1) {
      this.documento.descripcion = 'Documento de Certificado de postulación del beneficiario';
    } else if (this.documento.tipo_documentos == 2) {
      this.documento.descripcion = 'Contrato firmado entre beneficiario y agente operador';
    } else if (this.documento.tipo_documentos == 3) {
      this.documento.descripcion = 'Si la ejecución del programa no se cumple en las fechas del documento CONTRATO CON BENEFICIARIO, se debe generar un anexo de contrato el cual extienda las fechas de ejecución.';
    } else if (this.documento.tipo_documentos == 4) {
      this.documento.descripcion = 'Certificado de cumplimiento de obligaciones laborales y previsionales / Permite acreditar que un empleador (contratista o subcontratista) ha cumplido con las obligaciones laborales y previsionales que tiene para con sus trabajadores, el cual se obtiene de la página de ladirección del trabajo - Certificados de Cumplimiento F30-1 / Los beneficiarios que hayan incumplido las obligaciones laborales y previsionales, deben aduntar algun verificador el cuan indique que hayan realizado alguna gestión o negociación para regularizar su situación.';
    } else if (this.documento.tipo_documentos == 5) {
      this.documento.descripcion = 'El certificado de deuda se obtiene de la página web de Tesorería General de la República - Certificados y Comprobantes - Certificado de deuda fiscal. La fecha de emisión debe ser posterior a la fecha de postulación del beneficiario.';
    } else if (this.documento.tipo_documentos == 6) {
      this.documento.descripcion = 'Copia por ambos lados de cédula de identidad de representante legal, la cual debe coincidir con la identidad de los documentos solicitados anteriormente (Carpeta Trubitaria para solicitar créditos)';
    } else if (this.documento.tipo_documentos == 7) {
      this.documento.descripcion = 'Estatutos de la empresa se obtiene al momento de la creación de la empresa, existen dos tipos de obtención de empresa 1. Forma regular: los estatutos los tendrá el beneficiario de forma física 2. Empresa en un día: El beneficiario lo puede obtener del registro de empresas y sociedades, portal online.';
    } else if (this.documento.tipo_documentos == 8) {
      this.documento.descripcion = 'El certificado de vigencia de la empresa se puede obtener del  / La fecha de emisión del certificado de vigencia tendrá que ser no superior a 30 días antes de la fecha de firma del CONTRATO CON BENEFICIARIO';
    } else if (this.documento.tipo_documentos == 9) {
      this.documento.descripcion = 'Declaración jurada simple la cual viene anexada en las bases del programa';
    } else if (this.documento.tipo_documentos == 10) {
      this.documento.descripcion = 'Declaración jurada simple la cual viene anexada en las bases del programa';
    } else if (this.documento.tipo_documentos == 11) {
      this.documento.descripcion = 'Declaración jurada simple la cual viene anexada en las bases del programa';
    } else if (this.documento.tipo_documentos == 12) {
      this.documento.descripcion = 'Segun corresponda';
    } else if (this.documento.tipo_documentos == 13) {
      this.documento.descripcion = 'Segun corresponda';
    } else if (this.documento.tipo_documentos == 14) {
      this.documento.descripcion = 'Segun corresponda';
    }
  }


  // Subir documento
  seleccionarArchivoHeader(event) {
    var filesHeader = event.target.files;
    var fileHeader = filesHeader[0];
    this.archivoHeader.nombreArchivo = fileHeader.name;

    if (filesHeader && fileHeader) {
      var readerHeader = new FileReader();
      readerHeader.onload = this._handleReaderLoadedHeader.bind(this);
      readerHeader.readAsBinaryString(fileHeader);
    }
  }

  _handleReaderLoadedHeader(readerEvt) {
    var binaryStringHeader = readerEvt.target.result;
    this.archivoHeader.base64textString = btoa(binaryStringHeader);
  }

  donwload() {
    
  }

  uploadHeader() {
    this.aleatorio = Math.round(Math.random()*2000);
    this.archivoHeader.nombre = this.aleatorio + this.archivoHeader.nombreArchivo;
    this.archivoHeader.nombreArchivo = this.archivoHeader.nombre;
    this.archivoHeader.nombreUsuario = this.auth['data'].username;
    console.log(this.archivoHeader);
    this.uploadService.uploadFile(this.archivoHeader).subscribe(
      datos => {
        if(datos['resultado'] == 'OK') {
          this.documento.id_usuario = this.auth['data'].id;
          this.documento.ruta = this.archivoHeader.nombreArchivo;
          this.documento = new Documento;
          this.appService.addDocument(this.documento).subscribe(
            datos => {
              if(datos['code'] == 200) {
                this.getDocumentos();
                this.toastr.success('Éxito al subir', 'Documento subido');
              } else {
                console.log('Error al insertar');
                this.toastr.error('Error al subir', 'Documento no subido');
              }
            }
          )
        }
      }
    )
  }

  getDocumentos() {
    setTimeout(() => {
      for(let i = 0; i < this.empresas.length; i++) {
        this.idEmpresas[i] = this.empresas[i].id;
      }

      this.idEmpresasString = this.idEmpresas.toString();

      this.appService.getDocumentos(this.idEmpresasString).subscribe(
        datos => {
          if(datos['code'] == '200') {
            this.getDocuments = datos['data'];
          }
        }
      )
    }, 600);
  }

  removeDocument() {
    this.appService.deleteDocument(this.documentModal.id).subscribe(
      datos => {
        if(datos['code'] == '200') {
          this.getDocumentos();
          this.modalService.dismissAll();
          this.toastr.success('Éxito al eliminar', 'Documento eliminado');
        } else {
          this.toastr.error('Error al eliminar', 'Documento no eliminado');
        }
      }
    )
  }

  // Modal Bootstrap

  openModal(document, content) {
    this.documentModal = document;
    this.sanitizedUrl = this.sanitizer.bypassSecurityTrustUrl(this.documentModal.ruta);
    let route;
    route = this.documentModal.ruta.split('/');
    route = route[route.length - 1];
    route = this.documentModal.ruta.split('.');
    route = route[route.length - 1];
    this.typeimg = route;
    this.open(content, this.documentModal);
  }


  open(content, documentModal) {
    console.log(documentModal);
    this.modalService.open(content, { size: 'xl' });
  }
}
