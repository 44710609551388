export class User {
    id: string;
    username: string;
    nombre: string;
    password: string;
    email: string;
    perfil_ejecutivo: string;
    region: string;
    fecha_nacimiento: string;
    role: string;
    activo: string;
    name: string;
  }
  