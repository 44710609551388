<div class="profile-box">
  <div class="d-flex flex-profile">
    <div class="box-content-profile">
      <div class="profile-avatar">
        <img src="https://rciminternet.com/wp-content/uploads/2019/04/usuario.png" alt="avatar"/>
      </div>
    </div>

    <div class="box-content-profile-mid">
      <input class="form-control" placeholder="Nombre de usuario" type="text" [(ngModel)]="auth['data'].username" [disabled]="auth['data'].role == 0 || !modify"/>
      <input class="form-control" placeholder="Nombre" type="text" [(ngModel)]="auth['data'].nombre" [disabled]="!modify"/>
      <input class="form-control" placeholder="Apellidos" type="text" [(ngModel)]="auth['data'].apellidos" [disabled]="!modify"/>
      <input class="form-control" placeholder="E-mail" type="email" [(ngModel)]="auth['data'].email" [disabled]="!modify"/>
      <input class="form-control" placeholder="00/00/0000" type="date" [(ngModel)]="auth['data'].fecha_nacimiento" [disabled]="!modify"/>
    </div>
    <div class="box-content-profile-end">
      <input class="form-control" placeholder="Empresa" type="text" [(ngModel)]="auth['data'].name" [disabled]="auth['data'].role == 0 || !modify"/>
      <input class="form-control" placeholder="Región" type="text" [(ngModel)]="auth['data'].region" [disabled]="!modify">
      <input class="form-control" placeholder="Perfil Ejecutivo" [(ngModel)]="auth['data'].perfil_ejecutivo" [disabled]="auth['data'].role == 0 || !modify"/>
      <select class="custom-select mt-1" [(ngModel)]="auth['data'].activo" [disabled]="auth['data'].role == 0 || !modify">
        <option [ngValue]="'1'">Activo</option>
        <option [ngValue]="'0'">Inactivo</option>
      </select>
      <button class="btn-block btn btn-info mt-1" (click)="open(content)">Cambiar contraseña</button>
    </div>
    <button class="btn btn-block btn-sm btn-black" (click)="modifyInput()">{{modify_text}}</button>
  </div>
  <hr class="mt-4 mb-4" *ngIf="this.auth['data'].role == 1">
  <div class="add-user" *ngIf="this.auth['data'].role == 1">
    <div class="form-group">
      <div class="row">
        <div class="col-md-12">
          <div class="title-adduser">Agregar nuevo usuario</div>
        </div>
        <div class="col-md-4">
          <input type="text" name="username" placeholder="Username" class="form-control mb-2" [(ngModel)]="adduser.username"/>
        </div>
        <div class="col-md-4">
          <input type="password" name="password" placeholder="Password" class="form-control mb-2" [(ngModel)]="adduser.password"/>
        </div>
        <div class="col-md-4">
          <input type="text" name="nombre" placeholder="Nombre" class="form-control mb-2" [(ngModel)]="adduser.nombre"/>
        </div>
        <div class="col-md-4">
          <input type="text" name="apellidos" placeholder="Apellidos" class="form-control mb-2" [(ngModel)]="adduser.apellidos"/>
        </div>
        <div class="col-md-4">
          <input type="date" name="fecha_nacmiento" class="form-control mb-2" [(ngModel)]="adduser.fecha_nacimiento"/>
        </div>
        <div class="col-md-4">
          <input type="email" name="email" placeholder="E-mail" class="form-control mb-2" [(ngModel)]="adduser.email"/>
        </div>
        <div class="col-md-4">
          <input type="text" name="region" placeholder="Region" class="form-control mb-2" [(ngModel)]="adduser.region"/>
        </div>
        <div class="col-md-4">
          <select class="custom-select" [(ngModel)]="adduser.role">
            <option [value]="'selected'" selected disabled>Seleciona un rol</option>
            <option [value]="'0'">Usuario básico</option>
            <option [value]="'1'">Administrador</option>
          </select>
        </div>
        <div class="col-md-12">
          <button class="btn btn-block btn-box mt-3" (click)="addUser()">Agregar usuario</button>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Ventana Modal -->
<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Cambiar contraseña</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
      <form [formGroup]="form" (ngSubmit)="submit(content)">
          <div class="change-password">
              <div *ngIf="f.repeatpassword.touched && f.repeatpassword.invalid" class="alert alert-danger mb-2">
                  <div *ngIf="f.repeatpassword.errors.required">La contraseña es requerida.</div>
                  <div *ngIf="f.repeatpassword.errors.confirmedValidator">Las contraseñas no coinciden.</div>
              </div>
              <input type="password" formControlName="newpassword" class="form-control" placeholder="Nueva contraseña" name="password"/>
              <input type="password" formControlName="repeatpassword" class="form-control mt-2" placeholder="Repetir contraseña" name="repeatpassword"/>
              <button class="btn btn-block btn-sm btn-info mt-3" type="submit" [disabled]="!form.valid">Cambiar contraseña</button>
          </div>
      </form>
  </div>
</ng-template>
