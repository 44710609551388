import { Component, OnInit } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  public open:boolean;
  public mobile:boolean;
  constructor() {
    if(window.innerWidth < 1270) {
      this.open = true;
    } else {
      this.open = false;
      this.mobile = true;
    }

    if(window.innerWidth < 778) {
      this.mobile = true;
    } else {
      this.mobile = false;
    }
  }

  ngOnInit(): void {
    this.openSidebar();
  }

  openSidebar() {
    if(this.open) {
      this.open = false;
    } else {
      this.open = true;
    }
  }

}
