import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {

  transform(value: any, arg: any): any {
    const resultDocuments = [];
    for(const documents of value) {
      if(documents.username.toLowerCase().indexOf(arg.toLowerCase()) > -1) {
        resultDocuments.push(documents);
      }
    }
    return resultDocuments;
  }

}
