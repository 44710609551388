<div class="r-list">
    <div class="row">
        <div class="col-md-12" *ngIf="rendiciones < 1">
            <div class="alert alert-primary" role="alert">
                <i class="bi bi-info-circle-fill"></i> Actualmente no existen rendiciones para mostrar
            </div>
        </div>
        <div class="col-md-12" *ngFor="let rendicion of rendiciones">
            <div class="box-ren" (click)="open(content, rendicion)"><i class="bi bi-file-earmark-pdf-fill"></i> {{rendicion.nombre}}</div>
        </div>
    </div>
</div>

<!-- Ventana Modal -->
<ng-template #content let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">{{onlydocument.nombre}}</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        <img class="doc-img" *ngIf="typeimg == 'jpg' || typeimg == 'png' || typeimg == 'jpeg'" [src]="'assets/'+onlydocument.ruta" alt="imagen documento"/>
        <ngx-extended-pdf-viewer *ngIf="typeimg == 'pdf'" [src]="'assets/'+onlydocument.ruta" useBrowserLocale="true" height="80vh"></ngx-extended-pdf-viewer>
    </div>
    <div class="modal-footer">
        <button class="btn btn-sm btn-info" (click)="donwload(onlydocument)">Descargar documento</button>
        <button class="btn btn-sm btn-danger-documento" (click)="deleteDocument(onlydocument, proyectoModal)">Eliminar documento</button>
    </div>
</ng-template>
