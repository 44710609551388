import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { User } from '../models/user';
import { EmpresaService } from '../services/empresa.service';
import { Empresa } from '../models/empresa';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  private token: string;
  public usuario: User;
  public empresaInput : Empresa[]
  public error_login:boolean = false;
  public userloginrank;

  constructor(private router: Router, private authService: AuthService, private empresaService: EmpresaService) {
    this.usuario = new User();
  }

  ngOnInit(): void {
  }

  login() {
    this.error_login = false;
    /*if(localStorage.getItem('token') !== undefined && localStorage.getItem('token')) {

      if(this.token == localStorage.getItem('token')) {
        this.router.navigate(['/home']);
      } else {

      }
    } else {*/
      this.authService.SignUp(this.usuario).subscribe(
        response => {
          if(response['code'] == 200) {
            if(response['data'].id) {
              this.usuario = response['data'];

              this.authService.getEmpresa(this.usuario.id).subscribe(
                response => {
                  if(response['code'] == 200) {
                    this.empresaService.setEmpresas(response['data']);
                  }
                },
                error => {
                  console.log(<any>error);
                }
              );

              this.authService.getProyectuserLogin(this.usuario.id).subscribe(
                response => {
                  if(response['code'] == 200) {
                    this.userloginrank = response['data'];
                  }
                },
                error => {
                  console.log(<any>error);
                }
              );
              


              this.router.navigate(['/home']);
            } else {
              console.log('Error en la tramitación de datos');
            }

            /*Actualizamos el token del usuario
            this.authService.updateToken(this.identity.id, this.usuario).subscribe(
              response => {
                if(response['code'] == 200) {
                  this.router.navigate(['/admin'], { relativeTo: this.route });
                } else {
                  console.log('Error en la petición');
                }
              },
              error => {
                console.log(<any>error);
              }
            );*/
  
          } else {
            console.log('Error en la petición');
            this.error_login = true;
          }
        },
        error => {
          console.log(<any>error);
          this.error_login = true;
        }
      );
    //}

    //this.router.navigate(['/home']);
  }

}
