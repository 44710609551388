import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoginComponent } from '../login/login.component';
import { Auth } from '../interfaces/auth/auth.interface';
import { Authrol } from '../interfaces/auth/authrol.interface';

import { Empresa } from '../interfaces/auth/empresa.interface';
import { tap, map } from 'rxjs/operators';
import { User } from '../models/user';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { EmpresaService } from './empresa.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  //public url: string = 'https://agasdata.cl/plataforma/api/index.php';
  public url: string = 'https://autobateriasgranada.pw/apirest/index.php';
  private _auth: Auth | undefined;
  private authrol: Authrol | undefined;
  public user: User;
  public infopermisos;
  
  get auth(): Auth {
    return{ ...this._auth! }
  }

  get authrole(): Authrol {
    return{ ...this.authrol! }
  }

  constructor(public Http: HttpClient, private empresaService: EmpresaService) {
    /*setTimeout(() => {
      this.empresaService.setEmpresas([
        {
          nombre: 'Empresa 1'
        },
        {
          nombre: 'Empresa 2'
        }
      ]);
    }, 1000);*/
  }

  verifyAuth(): Observable<boolean> {
    if(!localStorage.getItem('token')) {
      return of(false);
    }

    let json = JSON.stringify(this.user);
    let params = 'json=' + json;
    let headers = new HttpHeaders({'Content-Type':'application/x-www-form-urlencoded'});
    return this.Http.post<Auth>(this.url+'/login', params, {headers})
      .pipe(
        map( auth => {
          this._auth = auth;
          this.verifyPermissions();
          return true;
        })
      )
  }

  logout() {
    this._auth = undefined;
  }

  SignUp(usuario:User) {
    this.user = usuario;
    let json = JSON.stringify(usuario);
    let params = 'json=' + json;
    let headers = new HttpHeaders({'Content-Type':'application/x-www-form-urlencoded'});
    return this.Http.post<Auth>(this.url+'/login', params, {headers})
      .pipe(
        tap( auth => this._auth = auth),
        tap( auth => localStorage.setItem('token', auth.token)),
      );
  }

  verifyPermissions(): Observable<any> {  
    console.log(this.infopermisos);
    return this.Http.get<Authrol>(this.url + '/get-proyectuserlogin/' + this.infopermisos)
    .pipe(
      map( authrole => {
        this.authrol = authrole;
        return true;
      })
    )
  }

  getProyectuserLogin(id) {
    this.infopermisos = id;
    return this.Http.get<Authrol>(this.url + '/get-proyectuserlogin/' + id)
    .pipe(
      tap( authrole => this.authrol = authrole),
    )
  }

  getEmpresa(id) {
    return this.Http.get<Empresa[]>(this.url + '/get-empresa/' + id);
  }

  getProyecto(empresasArraystring) {
    let json = JSON.stringify(empresasArraystring);
    let params = 'json=' + json;
    let headers = new HttpHeaders({'Content-Type':'application/x-www-form-urlencoded'});
    return this.Http.post(this.url + '/get-proyecto', params, {headers});
  }

  getUserProyect(id) {
    return this.Http.get(this.url + '/get-empleados-proyectos/' + id);
  }

  getAllProyectos() {
    return this.Http.get(this.url + '/get-allproyects');
  }

  getAllEmpresas() {
    return this.Http.get(this.url + '/get-allempresas');
  }

  getDocumentosFilter(documentofilter) {
    let json = JSON.stringify(documentofilter);
    let params = 'json=' + json;
    let headers = new HttpHeaders({'Content-Type':'application/x-www-form-urlencoded'});
    return this.Http.post(this.url + '/get-documentosfilter', params, {headers});
  }

  getOnlydocument(onlydocumentfilter) {
    let json = JSON.stringify(onlydocumentfilter);
    let params = 'json=' + json;
    let headers = new HttpHeaders({'Content-Type':'application/x-www-form-urlencoded'});
    return this.Http.post(this.url + '/get-onlydocument', params, {headers});
  }

  getUser(usuario:User) {
    let json = JSON.stringify(usuario);
    let params = 'json=' + json;
    let headers = new HttpHeaders({'Content-Type':'application/x-www-form-urlencoded'});
    return this.Http.post(this.url+'/login-user', params, {headers});
  }

  updateToken(id, usuario:User) {
    let json = JSON.stringify(usuario);
    let params = 'json=' + encodeURIComponent(json);
    let headers = new HttpHeaders({'Content-Type':'application/x-www-form-urlencoded'});
    return this.Http.post(this.url+'/update-token/'+id, params, {headers});
  }

  updatePassword(id, newpassword) {
    let json = JSON.stringify(newpassword);
    let params = 'json=' + encodeURIComponent(json);
    let headers = new HttpHeaders({'Content-Type':'application/x-www-form-urlencoded'});
    return this.Http.post(this.url+'/update-password/'+id, params, {headers});
  }

  deleteEmpleado(id) {
    return this.Http.get(this.url + '/delete-empleado/' + id);
  }
}
