export class Empresa {
    id: string;
    activo: string;
    email: string;
    nombre_rl: string;
    razon_social: string;
    rut: string;
    rut_rl: string;
    telefono_rp: string;
    id_empresa: string;
    id_usuario: string;
}