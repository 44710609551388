import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { AppService } from '../services/app.service';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, FormControl, Validators} from '@angular/forms';
import { passwordVerify } from '../usuarios/confirmpassword.validators';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  get auth() {
    return this.authService.auth;
  } 

  public modify:boolean = false;
  public modify_text:string = 'Modificar perfil';
  public adduser = {
    username: '',
    password: '',
    nombre: '',
    apellidos: '',
    fecha_nacimiento: '',
    email: '',
    permisos: '',
    perfil_ejecutivo: '',
    region: '',
    role: '',
    creado: '',
    activo: '',
  }
  form: FormGroup = new FormGroup({});

  constructor(private fb: FormBuilder, 
    private authService: AuthService, 
    private appService: AppService, 
    private toastr: ToastrService,
    private modalService: NgbModal,
    ) {
    this.adduser.role = 'selected';
    this.form = fb.group({
      newpassword: ['', [Validators.required]],
      repeatpassword: ['', [Validators.required]]
    }, { 
      validator: passwordVerify('newpassword', 'repeatpassword')
    })
  }

  ngOnInit(): void {
  }

  get f(){
    return this.form.controls;
  }

  submit(){
    if(this.auth['data'].role == 1) {
      this.authService.updatePassword(this.auth['data'].id, this.form.value.newpassword).subscribe(
        response => {
          if(response['code'] == 200) {
            this.modalService.dismissAll();
            this.toastr.success('Contraseña actualizada', 'Éxito al actualizar');
          } else {
            this.toastr.error('Contraseña no actualizada', 'Error al actualizar');
          }
        },
        error => {
          console.log(<any>error);
        }
      );
    } else {
      this.toastr.error('Empleado no eliminado', 'Error al eliminar');
    }
  }

  modifyInput() {
    if(this.modify == false) {
      this.modify = true;
      this.modify_text = 'Guardar cambios';
    } else {
      this.modify = false;
      this.modify_text = 'Modificar perfil';
      this.modifyUser();
    }
  }

  modifyUser() {
    console.log(this.auth['data']);
    this.auth['data'].password = null;
    this.appService.modifyUser(this.auth['data'].id, this.auth['data']).subscribe(
      response => {
        if(response['code'] == 200) {
          console.log('Actualizado con éxito');
          this.toastr.success('Éxito al modificar', 'Usuario modificado');
        } else {
          console.log('Error en la petición');
          this.toastr.error('Error al modificar', 'Usuario no modificado');
        }
      },
      error => {
        console.log(<any>error);
      }
    );
  }

  addUser() {
    if(this.auth['data'].role == 1) {
      this.adduser.activo = '1';
      this.adduser.creado = this.auth['data'].id;
      this.appService.newUser(this.adduser).subscribe(
        response => {
          if(response['code'] == 200) {
            console.log('Usuario agregado con éxito');
            this.adduser = {
              username: null,
              password: null,
              nombre: null,
              apellidos: null,
              fecha_nacimiento: null,
              email: null,
              permisos: null,
              perfil_ejecutivo: null,
              region: null,
              role: 'selected',
              creado: null,
              activo: null,
            }
            this.toastr.success('Éxito al crear', 'Usuario agregado');
          } else {
            console.log('Error en la petición');
            this.toastr.error('Error al crear', 'Usuario no creado');
          }
        },
        error => {
          console.log(<any>error);
        }
      );
    } else {
      console.log('No dispone de los suficientes permisos');
      this.toastr.error('Error al crear', 'Permisos insuficientes');
    }
  }

  open(content) {
    this.modalService.open(content, { size: 'xs' });
  }
}
