import { Injectable } from '@angular/core';
import { User } from '../models/user';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Documento } from '../models/documento';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  public url: string = 'https://autobateriasgranada.pw/apirest/index.php';

  constructor(public Http: HttpClient) { }

  modifyUser(id, usuario:User) {
    let json = JSON.stringify(usuario);
    let params = 'json=' + json;
    let headers = new HttpHeaders({'Content-Type':'application/x-www-form-urlencoded'});
    return this.Http.post(this.url+'/update-user/'+id, params, {headers});
  }

  addDocument(documento:Documento) {
    let json = JSON.stringify(documento);
    let params = 'json=' + json;
    let headers = new HttpHeaders({'Content-Type':'application/x-www-form-urlencoded'});
    return this.Http.post(this.url+'/add-document', params, {headers});
  }

  addUserEmpresa(usuario) {
    let json = JSON.stringify(usuario);
    let params = 'json=' + json;
    let headers = new HttpHeaders({'Content-Type':'application/x-www-form-urlencoded'});
    return this.Http.post(this.url+'/add-usuario-empresa', params, {headers});
  }

  addUserProyect(asignusertoproyect) {
    let json = JSON.stringify(asignusertoproyect);
    let params = 'json=' + json;
    let headers = new HttpHeaders({'Content-Type':'application/x-www-form-urlencoded'});
    return this.Http.post(this.url+'/add-userto-proyect', params, {headers});
  }

  addProyecto(proyecto) {
    let json = JSON.stringify(proyecto);
    let params = 'json=' + json;
    let headers = new HttpHeaders({'Content-Type':'application/x-www-form-urlencoded'});
    return this.Http.post(this.url+'/add-proyecto', params, {headers});
  }

  addUserEmpresanew(usuario) {
    let json = JSON.stringify(usuario);
    let params = 'json=' + json;
    let headers = new HttpHeaders({'Content-Type':'application/x-www-form-urlencoded'});
    return this.Http.post(this.url+'/add-usuario-empresa', params, {headers});
  }

  addnewEmpresa(empresa) {
    let json = JSON.stringify(empresa);
    let params = 'json=' + json;
    let headers = new HttpHeaders({'Content-Type':'application/x-www-form-urlencoded'});
    return this.Http.post(this.url+'/add-empresa', params, {headers});
  }

  newUser(usuario) {
    let json = JSON.stringify(usuario);
    let params = 'json=' + json;
    let headers = new HttpHeaders({'Content-Type':'application/x-www-form-urlencoded'});
    return this.Http.post(this.url+'/add-usuario', params, {headers});
  }

  updateEmpresa(id, empresa) {
    let json = JSON.stringify(empresa);
    let params = 'json=' + json;
    let headers = new HttpHeaders({'Content-Type':'application/x-www-form-urlencoded'});
    return this.Http.post(this.url+'/update-empresa/' + id , params, {headers});
  }

  updateProyecto(id, proyectoupdate) {
    let json = JSON.stringify(proyectoupdate);
    let params = 'json=' + json;
    let headers = new HttpHeaders({'Content-Type':'application/x-www-form-urlencoded'});
    return this.Http.post(this.url+'/update-proyecto/' + id , params, {headers});
  }

  getDocumentos(id) {
    return this.Http.get(this.url + '/get-documentos/' + id);
  }

  getRend(id) {
    return this.Http.get(this.url + '/get-rendiciones/' + id);
  }

  getAllRend() {
    return this.Http.get(this.url + '/get-allrendiciones');
  }

  getEmpleadosEmpresa(id) {
    return this.Http.get(this.url + '/get-empleados-empresa/' + id);
  }

  getEmpresa() {
    return this.Http.get(this.url + '/get-empresa');
  }

  getEmpresaProyecto(id) {
    return this.Http.get(this.url + '/get-proyectempresas/' + id);
  }

  deleteEmpleadoEmpresa(id) {
    return this.Http.get(this.url + '/delete-empleado-empresa/' + id);
  }

  getEmpleados(id) {
    return this.Http.get(this.url + '/get-empleados/' + id);
  }

  getUsertoproyect(id) {
    return this.Http.get(this.url + '/get-usertoproyect/' + id);
  }

  getAllEmpleados() {
    return this.Http.get(this.url + '/get-allempleados');
  }

  deleteDocument(id) {
    return this.Http.get(this.url + '/delete-document/' + id);
  }

  deleteUserProyect(id, update) {
    let json = JSON.stringify(update);
    let params = 'json=' + json;
    let headers = new HttpHeaders({'Content-Type':'application/x-www-form-urlencoded'});
    return this.Http.post(this.url + '/delete-userproyect/' + id, params, {headers});
  }

  deleteDocumento(id) {
    return this.Http.get(this.url + '/delete-document/' + id);
  }

  deleteEmpresa(id) {
    return this.Http.get(this.url + '/delete-empresa/' + id);
  }

  deleteProyecto(id) {
    return this.Http.get(this.url + '/delete-proyecto/' + id);
  }
}
